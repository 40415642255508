import axios from "axios";
import Header from "components/Headers/Header";
import AddPanchanga from "pages/DailyPanchanga/AddPanchanga";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Button,
} from "reactstrap";
import UpdatePanchanga from "./UpdatePanchanga";
import { url, ngrok } from "../../config.json";
import { useToasts } from "react-toast-notifications";
import DeletePanchanga from "./DeletePanchanga";
import { useHistory } from "react-router-dom";

const Paginationn = ({ items, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, items.length);
  const [loading, setLoading] = useState({
    list: false,
    add: false,
    update: false,
    delete: false,
  });
  const [dialogs, setDialogs] = useState({
    addDialog: false,
    deleteDialog: false,
    updateDialog: false,
  });
  const [type, setType] = useState("");
  const totalPages = Math.ceil(items.length / itemsPerPage);
  const [respondData, setRespondData] = useState({
    date: "",
    karana: "",
    nakshatra: "",
    thithi: "",
    vaaara: "",
    yoga: "",
    id: "",
  });
  const { addToast } = useToasts();
  const history = useHistory();
  const access_token = localStorage.getItem("token");

  useEffect(() => {
    const loginTime = localStorage.getItem("expirationTime");
    const elapsedTime = Date.now() - loginTime;

    if (elapsedTime > 24 * 60 * 60 * 1000) {
      // Check if 24 hours have elapsed
      localStorage.removeItem("token");
      localStorage.removeItem("expirationTime");
      history.push("/login");
    }
  }, []);

  const handleOpenDialog = (e, dialog, data) => {
    setType(dialog);
    if (data) {
      setRespondData({
        karana: data.karana,
        date: data.date,
        nakshatra: data.nakshatra,
        thithi: data.thithi,
        vaaara: data.vaaara,
        yoga: data.yoga,
        id: data.daily_panchanga_id,
      });
    }
    setDialogs({ ...dialogs, [dialog]: true });
  };

  const handleClose = (dialog) => {
    setDialogs({ ...dialogs, [dialog]: false });
  };

  const handleNextPage = () => {
    if (items.length === 0) {
      setCurrentPage(currentPage);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleRespondDataChange = (e) => {
    let d = { ...respondData };
    d[e.target.name] = e.target.value;
    setRespondData(d);
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    axios
      .put(
        `${url}/api/panchanga/update_panchanga/${respondData.id}`,
        respondData,
        {
          headers: {
            "x-access-token": access_token,
          },
        }
      )
      .then((res) => {
        if (res.data.responseCode === 200) {
          setDialogs({ updateDialog: false });
          addToast("Panchanga updated successfully", {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast(res.data.responseData, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const handleDelete = () => {
    axios
      .delete(`${url}/api/panchanga/delete_panchanga/${respondData.id}`, {
        headers: { "x-access-token": access_token },
      })
      .then((res) => {
        setDialogs({ deleteDialog: false });
        addToast("Panchanga deleted successfully...", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((e) => {
        addToast(e, { appearance: "error", autoDismiss: true });
      });
  };

  return (
    <div>
      <Table className="align-items-center table-flush" responsive>
        <thead style={{ backgroundColor: "#f6f9fc", color: "#8898aa" }}>
          <tr>
            <th className="text-left" scope="col">
              Date
            </th>
            <th className="text-left" scope="col">
              Thithi
            </th>
            <th className="text-left" scope="col">
              Nakshatra
            </th>
            <th className="text-left" scope="col">
              Vaara
            </th>
            <th className="text-left" scope="col">
              Yoga
            </th>
            <th className="text-left" scope="col">
              Karana
            </th>
            <th className="text-center" scope="col">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {loading.list ? (
            <tr>
              <td colSpan={6} style={{ width: "100%", textAlign: "center" }}>
                <Spinner type="grow" color="primary" />
              </td>
            </tr>
          ) : currentItems.length > 0 ? (
            // getPaginatedData().map((e) => (
            currentItems.map((e, i) => (
              <tr key={e.daily_panchanga_id}>
                <td className="text-left">{e.date}</td>
                <td className="text-left">{e.thithi}</td>
                <td className="text-left">{e.nakshatra}</td>
                <td className="text-left">{e.vaaara}</td>
                <td className="text-left">{e.yoga}</td>
                <td className="text-left">{e.karana}</td>
                <td className="text-center">
                  <i
                    className="fa fa-edit text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={(event) =>
                      handleOpenDialog(event, "updateDialog", e)
                    }
                  />
                  <i
                    className="fa fa-trash text-danger"
                    style={{ cursor: "pointer", marginLeft: "50px" }}
                    onClick={(event) =>
                      // handleDeleteDialog(e.daily_panchanga_id)
                      handleOpenDialog(event, "deleteDialog", e)
                    }
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr className="text-center">
              <th colSpan="7">No Data Found!!</th>
            </tr>
          )}
        </tbody>
      </Table>
      <Container className="justify-content-end align-items-center d-flex mr-1 mt-2 mb-2">
        <Container className="d-flex align-items-center justify-content-end">
          <p style={{ marginBottom: "0", fontWeight: "500" }}>
            {startIndex}-{endIndex} /{items.length}
          </p>
        </Container>
        <Button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          style={{ borderRadius: "50px" }}
          className="bg-primary text-white"
        >
          <i className="fas fa-angle-left" />
        </Button>
        <Button
          onClick={handleNextPage}
          style={{ borderRadius: "50px" }}
          disabled={currentPage === totalPages}
          className="bg-primary text-white"
        >
          <i className="fas fa-angle-right" />
        </Button>
      </Container>

      <UpdatePanchanga
        heading={"Update Panchanga"}
        isOpen={dialogs.updateDialog}
        data={respondData}
        onClose={handleClose}
        handleUpdate={handleUpdate}
        onDataChange={handleRespondDataChange}
        loading={loading.update}
      />
      <DeletePanchanga
        heading={"Delete Panchanga"}
        isOpen={dialogs.deleteDialog}
        onClose={handleClose}
        loading={loading.delete}
        handleDelete={handleDelete}
      />
    </div>
  );
};

function Index() {
  const [panchangaList, setPanchangaList] = useState([]);
  const [type, setType] = useState("");
  const [dialogs, setDialogs] = useState({
    addDialog: false,
    deleteDialog: false,
    updateDialog: false,
  });
  const [loading, setLoading] = useState({
    list: false,
    add: false,
    update: false,
    delete: false,
  });
  const { addToast } = useToasts();
  const access_token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(`${url}/api/panchanga/list_panchanga`, {
        headers: {
          "x-access-token": access_token,
        },
      })
      .then((res) => {
        if (res.data.responseCode === 200) {
          setLoading({ ...loading, list: false });
          setPanchangaList(res.data.responseData);
        } else {
          addToast(res.data.responseMessage, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }).catch = (err) => {
      addToast(err, {
        appearance: "error",
        autoDismiss: true,
      });
    };
  }, [panchangaList]);

  const handleOpenDialog = (e, dialog, data) => {
    setType(dialog);
    // if (data) {
    //   setRespondData(data);
    // }
    setDialogs({ ...dialogs, [dialog]: true });
  };

  const handleClose = (dialog) => {
    setDialogs({ ...dialogs, [dialog]: false });
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className=" shadow">
              <CardHeader className="bg-transparent border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Daily Panchanga</h3>
                  </div>
                  <div className="col text-right">
                    <div
                      className="icon icon-shape bg-primary text-white rounded-circle shadow"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleOpenDialog(e, "addDialog")}
                    >
                      <i className="fas fa-plus" style={{ fontSize: 10 }}></i>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <Paginationn items={panchangaList} itemsPerPage={5} />
            </Card>
          </Col>
        </Row>
      </Container>

      <AddPanchanga
        heading={"Add New Panchanga"}
        isOpen={dialogs.addDialog}
        isModalClose={() => setDialogs({ addDialog: false })}
        // data={data}
        onClose={handleClose}
        // handleAdd={handleAdd}
        // onDataChange={handleDataChange}
        loading={loading.add}
      />
    </>
  );
}

export default Index;
