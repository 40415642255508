import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Button,
  Spinner,
  CardFooter,
  Pagination,
  PaginationItem,
  Form,
  CardHeader,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  PaginationLink,
} from "reactstrap";
const TablePagination = ({
  page,
  upper,
  lower,
  rowsPerPage,
  handleChangePage,
  btnNextClick,
  btnPrevClick,
  count,
}) => {
  let pageNumbers = [];
  for (let i = 1; i <= Math.ceil(count / rowsPerPage); i++) {
    pageNumbers.push(i);
  }
  const renderPageNumbers = pageNumbers.map((number) => {
    if (number === 1 && page === 1) {
      return (
        <PaginationItem className={number == page ? `active` : ""} key={number}>
          <PaginationLink
            tag="button"
            onClick={(e) => handleChangePage(e, number)}
          >
            {number}
          </PaginationLink>
        </PaginationItem>
      );
    } else if (number < upper + 1 && number > lower) {
      return (
        <PaginationItem key={number} className={number == page ? `active` : ""}>
          <PaginationLink
            tag="button"
            onClick={(e) => handleChangePage(e, number)}
          >
            {number}
          </PaginationLink>
        </PaginationItem>
      );
    }
  });
  return (
    <>
      <nav aria-label="...">
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <PaginationItem className={page <= 1 ? `disabled` : ""}>
            <PaginationLink
              tag="button"
              onClick={(e) => btnPrevClick(e)}
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          <>{renderPageNumbers}</>

          <PaginationItem
            className={page == Math.ceil(count / rowsPerPage) ? `disabled` : ""}
          >
            <PaginationLink tag="button" onClick={(e) => btnNextClick(e)}>
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </nav>
    </>
  );
};
export default TablePagination;
