import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import Details from "./Details";

const PartnerProfile = ({p, countryList}) => {
  return (
    // <Row className="mt-4">
    //   <Col>
    //     <Card className="bg-secondary shadow">
    //       <CardHeader className="bg-white border-0">
    //         <Row className="align-items-center">
    //           <Col>
    //             <h6 className="heading-small">Partner Profile Preference</h6>
    //           </Col>
    //         </Row>
    //       </CardHeader>
    //       <CardBody>
            <Row className="mt-4">
              <Col xl="12">
              <h6 className="heading-small">Partner Profile Preference</h6>
              </Col>
              <Col xl="4" >
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="heading-small">Profile details</h6>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Details label={`Age`} description={p.partner_age ? p.partner_age : '-'} />
                      <Details label={`Height`} description={p.partner_height ? p.partner_height :'-'} />
                      <Details
                        label={`Marital Status`}
                        description={p.partner_marital ? p.partner_marital : '-'}
                      />
                      <Details
                        label={`Physical status`}
                        description={p.partner_physical ? p.partner_physical : '-'}
                      />
                      <Details
                        label={`Life style preference`}
                        description={p.partner_lifestyle ? p.partner_lifestyle : '-'}
                      />
                      <Details
                        label={`Education`}
                        description={p.partner_education ? p.partner_education : '-'}
                      />
                      <Details
                        label={`Occupation`}
                        description={p.partner_occupation ? p.partner_occupation : '-'}
                      />
                      <Details
                        label={`Employed in `}
                        description={p.partner_employed_in ? p.partner_employed_in : '-'}
                      />
                      <Details
                        label={`Annual income`}
                        description={p.partner_annual_income ? p.partner_annual_income +" "+ p.partner_currency : '-'}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4"  className="mt-4 mt-md-0">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white">
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="heading-small">Religious Preference</h6>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Details label={`Mother tongue`} description={p.partner_mother_tongue ? p.partner_mother_tongue :'-'} />
                      <Details label={`Caste`} description={p.partner_caste ? p.partner_caste : '-'} />
                      <Details label={`Star`} description={p.partner_star ? p.partner_star :'-'} />
                      <Details label={`Manglik`} description={p.partner_manglik ? p.partner_manglik :'-'} />
                      <Details label={`Gothra`} description={p.partner_gotra ? p.partner_gotra :'-'} />
                      <Details label={`Should horoscope match`} description={p.partner_horoscope_match ? p.partner_horoscope_match : '-'} />
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="4"  className="mt-4 mt-md-0">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white">
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="heading-small">Location Preference</h6>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Details
                        label={`Country Living in`}
                        description={p.partner_country ? countryList[p.partner_country] : '-'}
                      />
                      <Details label={`State`} description={p.partner_state ? p.partner_state :'-'} />
                      <Details label={`City`} description={p.partner_city ? p.partner_city : '-'} />
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="4" className="mt-4">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white">
            <Row className="align-items-center">
              <Col>
                <h6 className="heading-small">Lifestyle Details</h6>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Details label={`Eating habits`} description={p.partner_eating ? p.partner_eating : '-'} />
              <Details label={`Smoking habits`} description={p.partner_smoking ? p.partner_smoking : '-'} />
              <Details label={`Drinking habits`} description={p.partner_drinking ? p.partner_drinking : '-'} />
            </Row>
          </CardBody>
        </Card>
      </Col>
            </Row>
    //       </CardBody>
    //     </Card>
    //   </Col>
    // </Row>
  );
};
export default PartnerProfile;
