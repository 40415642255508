import React from "react";
import { Button, Col, Form, FormGroup, Input, Modal, Row } from "reactstrap";

function UpdatePanchanga({
  heading,
  isOpen,
  data,
  handleUpdate,
  onDataChange,
  onClose,
  loading,
}) {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => onClose("updateDialog")}
      backdrop="static"
    >
      <div className="modal-header">
        <h2 className="modal-title" id="modal-title-default">
          {heading}
        </h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onClose("updateDialog")}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Date"
                  type="date"
                  name="date"
                  onChange={(e) => onDataChange(e)}
                  value={data.date}
                  disabled={true}
                  style={{ cursor: "not-allowed" }}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Nakshatra"
                  type="text"
                  name="nakshatra"
                  onChange={(e) => onDataChange(e)}
                  value={data.nakshatra}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Thithi"
                  type="text"
                  name="thithi"
                  onChange={(e) => onDataChange(e)}
                  value={data.thithi}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Vaara"
                  type="text"
                  name="vaaara"
                  onChange={(e) => onDataChange(e)}
                  value={data.vaaara}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Yoga"
                  type="text"
                  name="yoga"
                  onChange={(e) => onDataChange(e)}
                  value={data.yoga}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Karana"
                  type="text"
                  name="karana"
                  onChange={(e) => onDataChange(e)}
                  value={data.karana}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="primary"
          outline
          data-dismiss="modal"
          type="button"
          onClick={() => onClose("updateDialog")}
        >
          Close
        </Button>
        <Button
          color="primary"
          type="button"
          onClick={handleUpdate}
          disabled={
            data.date.trim() === "" ||
            data.karana.trim() === "" ||
            data.nakshatra.trim() === "" ||
            data.thithi.trim() === "" ||
            data.vaaara.trim() === "" ||
            data.yoga.trim() === ""
              ? true
              : false
          }
        >
          {loading ? `Updating..` : `Update`}
        </Button>
      </div>
    </Modal>
  );
}

export default UpdatePanchanga;
