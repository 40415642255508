import axios from "axios";
import logger from "./logService";
import { apiUrl } from "../config.json";

const instance = axios.create({
  // baseURL: "http://ahmtest.puttigemutt.org:5000/",
  baseURL: apiUrl,
});

instance.interceptors.request.use(
  (config) => {
    const apiToken = localStorage.getItem("token");
    if (localStorage.getItem("code") != null) {
      config.headers = { "x-access-token": localStorage.getItem("code") };
    } else {
      config.headers = { "x-access-token": apiToken };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (expectedError) {
    logger.log(error);
    // toast.error("An unexpected error occured");
  }

  return Promise.reject(error);
});

export const http = instance;
