import React, { useState } from "react";
import { Button, Col, Form, FormGroup, Input, Modal, Row } from "reactstrap";
import axios from "axios";
import { url, ngrok } from "../../config.json";
import { useToasts } from "react-toast-notifications";

function AddPanchanga({ isOpen, onClose, heading, loading, isModalClose }) {
  const [data, setData] = useState({
    date: new Date().toISOString().substr(0, 10),
    nakshatra: "",
    thithi: "",
    vaaara: "",
    yoga: "",
    karana: "",
  });
  const { addToast } = useToasts();
  const access_token = localStorage.getItem("token");

  const onDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${url}/api/panchanga/add_panchanga`, data, {
        headers: { "x-access-token": access_token },
      })
      .then((res) => {
        if (res.data.responseCode === 200) {
          addToast("Panchanga added successfully", {
            appearance: "success",
            autoDismiss: "true",
          });
          isModalClose();
          setData({
            date: "",
            nakshatra: "",
            thithi: "",
            vaaara: "",
            yoga: "",
            karana: "",
          });
        } else {
          addToast(res.data.responseMessage, {
            appearance: "error",
            autoDismiss: "true",
          });
        }
      })
      .catch((err) => {
        addToast(err, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => onClose("addDialog")}
      backdrop="static"
    >
      <div className="modal-header">
        <h2 className="modal-title" id="modal-title-default">
          {heading}
        </h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onClose("addDialog")}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Date"
                  type="date"
                  name="date"
                  onChange={(e) => onDataChange(e)}
                  value={data.date}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Nakshatra"
                  type="text"
                  name="nakshatra"
                  onChange={(e) => onDataChange(e)}
                  value={data.nakshatra}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Thithi"
                  type="text"
                  name="thithi"
                  onChange={(e) => onDataChange(e)}
                  value={data.thithi}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Vaara"
                  type="text"
                  name="vaaara"
                  onChange={(e) => onDataChange(e)}
                  value={data.vaaara}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Yoga"
                  type="text"
                  name="yoga"
                  onChange={(e) => onDataChange(e)}
                  value={data.yoga}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Karana"
                  type="text"
                  name="karana"
                  onChange={(e) => onDataChange(e)}
                  value={data.karana}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="primary"
          outline
          data-dismiss="modal"
          type="button"
          onClick={() => onClose("addDialog")}
        >
          Close
        </Button>
        <Button
          color="primary"
          type="button"
          onClick={handleSubmit}
          disabled={
            data.date.trim() === "" ||
            data.karana.trim() === "" ||
            data.nakshatra.trim() === "" ||
            data.thithi.trim() === "" ||
            data.vaaara.trim() === "" ||
            data.yoga.trim() === ""
              ? true
              : false
          }
        >
          {loading ? `Adding..` : `Add`}
        </Button>
      </div>
    </Modal>
  );
}

export default AddPanchanga;
