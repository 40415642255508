import { http } from "./httpService";
import { apiUrl } from "../config.json";

const apiEndPointNotification = apiUrl + "version";

const addVersion = (data) => {
  return http.post(`${apiEndPointNotification}/add_version`, data);
};

const getVersionList = (data) => {
  return http.get(`${apiEndPointNotification}/get_version_list`, { params: data });
};

const updateVersion = (data) => {
  return http.put(`${apiEndPointNotification}/update_version`, data);
};

const updateMaintenance = (data) => {
  return http.put(`${apiEndPointNotification}/maintenance`, data);
};

export { addVersion, getVersionList, updateVersion, updateMaintenance };
