import { Switch } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { url } from "../../config.json";

function ManageCategory({ isOpen, heading, onClose, loading, currentItems }) {
  const [nestedAddModal, setNestedAddModal] = useState(false);
  const [nestedEditModal, setNestedEditModal] = useState(false);
  const [nestedDeleteModal, setNestedDeleteModal] = useState(false);
  const [editCategory, setEditCategory] = useState({
    id: "",
    category: "",
    status: "",
  });
  const [deleteCategory, setDeleteCategory] = useState();
  const access_token = localStorage.getItem("token");
  const { addToast } = useToasts();
  const [addData, setAddData] = useState({ category: "" });

  const toggleNestedEdit = (data) => {
    setNestedEditModal(!nestedEditModal);

    setEditCategory({
      ...editCategory,
      id: data.vendor_ads_category_id,
      category: data.category,
      status: data.status,
    });
  };

  const toggleNestedDelete = (data) => {
    setNestedDeleteModal(!nestedDeleteModal);
    setDeleteCategory(data.vendor_ads_category_id);
  };
  const toggleNestedAdd = () => {
    setNestedAddModal(!nestedAddModal);
  };

  const handleStatusChange = async (e, data) => {
    let statusFlag = e.target.checked === true ? 1 : 0;

    await axios
      .put(
        `${url}/api/vendor_ads/edit_ads_cat/${data.vendor_ads_category_id}`,
        {
          status: statusFlag,
          category: data.category,
        },
        { headers: { "x-access-token": access_token } }
      )
      .then((res) => {
        addToast(
          e.target.checked === false
            ? "Category status enabled successfully"
            : "Category status disabled successfully",
          {
            appearance: "success",
            autoDismiss: true,
          }
        );
      })
      .catch((err) => {
        addToast(err, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleDeleteCategory = () => {
    axios
      .delete(`${url}/api/vendor_ads/delete_ads_cat/${deleteCategory}`, {
        headers: { "x-access-token": access_token },
      })
      .then((res) => {
        setNestedDeleteModal(false);
        addToast("Category deleted successfully...", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        addToast(err, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .put(
        `${url}/api/vendor_ads/edit_ads_cat/${editCategory.id}`,
        {
          status: editCategory.status,
          category: editCategory.category,
        },
        { headers: { "x-access-token": access_token } }
      )
      .then((res) => {
        setNestedEditModal(false);
        addToast("Category updated successfully...", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        addToast(err, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleAddSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${url}/api/vendor_ads/add_ads_cat`, addData, {
        headers: { "x-access-token": access_token },
      })
      .then((res) => {
        if (res.data.responseCode === 200) {
          setAddData({ ...addData, category: "" });
          addToast("Category added successfully...", {
            appearance: "success",
            autoDismiss: true,
          });
          setNestedAddModal(false);
        } else {
          addToast(res.data.responseMessage, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={() => onClose("addDialog")}
        backdrop="static"
        scrollable={true}
      >
        <div className="modal-header">
          <h2 className="modal-title" id="modal-title-default">
            {heading}
          </h2>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => onClose("categoryDialog")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Row className="justify-content-end px-3 pb-2">
          <div
            className="p-2 bg-primary text-white rounded shadow row align-items-center"
            style={{ cursor: "pointer", marginRight: "10px" }}
            onClick={toggleNestedAdd}
          >
            <i
              class="fas fa-plus"
              style={{ fontSize: 10, marginRight: "5px" }}
            ></i>
            Add Category
          </div>
        </Row>
        <div className="modal-body p-2">
          <Table className="align-items-center table-flush" responsive>
            <thead style={{ backgroundColor: "#f6f9fc", color: "#8898aa" }}>
              <tr>
                <th className="text-left" scope="col">
                  Category Name
                </th>
                <th className="text-center" scope="col">
                  Status
                </th>
                <th className="text-center" scope="col">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {loading?.list ? (
                <tr>
                  <td
                    colSpan={6}
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <Spinner type="grow" color="primary" />
                  </td>
                </tr>
              ) : currentItems.length > 0 ? (
                currentItems.map((e, i) => (
                  <tr key={e.vendor_ads_category_id}>
                    <td className="text-left">{e.category}</td>

                    <td className="text-center">
                      <Switch
                        color="primary"
                        checked={e.status === 1 ? true : false}
                        onChange={(event) => handleStatusChange(event, e)}
                        name="status"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </td>
                    <td className="text-center">
                      <i
                        className="fa fa-edit text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => toggleNestedEdit(e)}
                      />
                      <i
                        className="fa fa-trash text-danger"
                        style={{ cursor: "pointer", marginLeft: "50px" }}
                        onClick={() => toggleNestedDelete(e)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <th colSpan="11">No Data Found!!</th>
                </tr>
              )}
            </tbody>
          </Table>
          {/* <Button color="primary" type="submit">
              {loading ? `Adding..` : `Add`}
            </Button> */}
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="primary"
            outline
            data-dismiss="modal"
            type="button"
            onClick={() => onClose("categoryDialog")}
          >
            Close
          </Button>
        </div>
      </Modal>

      {/* add modal  */}
      <Modal
        isOpen={nestedAddModal}
        toggle={toggleNestedAdd}
        backdrop="static"
        // onClosed={closeAll ? toggle : undefined}
      >
        <div className="modal-header">
          <h2 className="modal-title" id="modal-title-default">
            Add Category
          </h2>
        </div>
        <Form onSubmit={handleAddSubmit}>
          <ModalBody className="py-0">
            <Row>
              <Col md="12">
                <FormGroup>
                  <Input
                    className="form-control-alternative"
                    placeholder="Enter Category Name"
                    type="text"
                    name="title"
                    onChange={(e) =>
                      setAddData({
                        ...addData,
                        category: e.target.value,
                      })
                    }
                    value={addData.category}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="pt-0">
            <Button color="primary" outline onClick={toggleNestedAdd}>
              Close
            </Button>{" "}
            <Button color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      {/* edit modal  */}
      <Modal
        isOpen={nestedEditModal}
        toggle={toggleNestedEdit}
        backdrop="static"
        // onClosed={closeAll ? toggle : undefined}
      >
        <div className="modal-header">
          <h2 className="modal-title" id="modal-title-default">
            Edit Category
          </h2>
        </div>
        <Form onSubmit={handleSubmit}>
          <ModalBody className="py-0">
            <Row>
              <Col md="12">
                <FormGroup>
                  <Input
                    className="form-control-alternative"
                    placeholder="Enter Category Name"
                    type="text"
                    name="title"
                    onChange={(e) =>
                      setEditCategory({
                        ...editCategory,
                        category: e.target.value,
                      })
                    }
                    value={editCategory.category}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="pt-0">
            <Button color="primary" outline onClick={toggleNestedEdit}>
              Close
            </Button>{" "}
            <Button color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      {/* delete modal  */}
      <Modal
        isOpen={nestedDeleteModal}
        toggle={toggleNestedDelete}
        backdrop="static"
      >
        <div className="modal-header">
          <h2 className="modal-title" id="modal-title-default">
            Delete Category
          </h2>
        </div>
        <Form>
          <ModalBody className="py-0">
            <h3 style={{ padding: "0" }}>
              Do you want to delete this category?
            </h3>
          </ModalBody>
          <ModalFooter className="pt-0">
            <Button color="primary" outline onClick={toggleNestedDelete}>
              Close
            </Button>{" "}
            <Button color="primary" onClick={handleDeleteCategory}>
              Delete
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default ManageCategory;
