import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  Media,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import TablePagination from "./components/TablePagination";
import { getPaymentList } from "../../services/premiumAnalyticsService";
import { useHistory } from "react-router-dom";

let stateColor = {
  Processing: "yellow",
  Failed: "red",
  Success: "green",
};

const PremiumAnalytics = () => {
  const { addToast } = useToasts();
  const [list, setList] = useState([]);
  const [premiumAnalyticsList, setPremiumAnalyticsList] = useState([]);
  const [pagination, setPagination] = useState({
    upperPageBound: 3,
    lowerPageBound: 0,
    pageBound: 3,
    count: 0,
  });
  const [loading, setLoading] = useState({
    list: false,
    add: false,
    update: false,
    delete: false,
  });
  const dataLimit = 10;
  const [pages, setPages] = useState(Math.round(list.length / dataLimit));
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const loginTime = localStorage.getItem("expirationTime");
    const elapsedTime = Date.now() - loginTime;

    if (elapsedTime > 24 * 60 * 60 * 1000) {
      // Check if 24 hours have elapsed
      localStorage.removeItem("token");
      localStorage.removeItem("expirationTime");
      history.push("/login");
    }

    fetchData();
  }, []);

  let stateNames = {
    0: "All Payments",
    1: "Successfull Payments",
    2: "Processing Payments",
    3: "Failed Payments",
  };

  const fetchData = async (filter) => {
    setLoading({ ...loading, list: true });
    try {
      let requestParams = {
        access_token: localStorage.getItem("token"),
        last_index: 0,
        limit: 100000000,
        filter_type: filter,
      };
      setFilterType(filter ? filter : 0);

      let res = await getPaymentList(requestParams);
      if (res.data.responseCode == 200) {
        setLoading({ ...loading, list: false });
        setPages(
          Math.ceil(res.data.responseData.payment_list.length / dataLimit)
        );
        setPremiumAnalyticsList(res.data.responseData.payment_list);
        console.log(
          "res.data.responseData.payment_list = ",
          res.data.responseData.payment_list
        );
        setList(res.data.responseData.payment_list);
      } else {
        addToast(res.data.responseMessage, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleFilter = (filter) => {
    try {
      fetchData(filter);
    } catch (err) {
      addToast(err.response.data, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  function goToNextPage() {
    if (currentPage + 1 > pagination.upperPageBound) {
      pagination.upperPageBound =
        pagination.upperPageBound + pagination.pageBound;
      pagination.lowerPageBound =
        pagination.lowerPageBound + pagination.pageBound;
    }
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    if ((currentPage - 1) % pagination.pageBound === 0) {
      pagination.upperPageBound =
        pagination.upperPageBound - pagination.pageBound;
      pagination.lowerPageBound =
        pagination.lowerPageBound - pagination.pageBound;
    }
    setCurrentPage((page) => page - 1);
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return premiumAnalyticsList.slice(startIndex, endIndex);
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-transparent border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Premium Analytics</h3>
                  </div>
                  <div className="col text-right">
                    <div>
                      <UncontrolledDropdown nav>
                        <DropdownToggle nav>
                          <Button
                            className="ml-auto"
                            color="primary"
                            outline
                            type="button"
                            // style={{ color: "white" }}
                            size="sm"
                          >
                            {stateNames[filterType]}
                          </Button>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              handleFilter(0);
                            }}
                          >
                            <h5 className="text-overflow m-0">All Payments</h5>
                          </DropdownItem>

                          <DropdownItem divider />

                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              handleFilter(1);
                            }}
                          >
                            <h5 className="text-overflow m-0">
                              Successfull Payments
                            </h5>
                          </DropdownItem>

                          <DropdownItem divider />

                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              handleFilter(3);
                            }}
                          >
                            <h5 className="text-overflow m-0">
                              Failed Payments
                            </h5>
                          </DropdownItem>

                          <DropdownItem divider />

                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              handleFilter(2);
                            }}
                          >
                            <h5 className="text-overflow m-0">
                              Processing Payments
                            </h5>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead style={{ backgroundColor: "#f6f9fc", color: "#8898aa" }}>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Date</th>
                    <th scope="col">State</th>
                  </tr>
                </thead>
                <tbody>
                  {loading.list ? (
                    <tr>
                      <td
                        colSpan={6}
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        <Spinner type="grow" color="primary" />
                      </td>
                    </tr>
                  ) : premiumAnalyticsList.length > 0 ? (
                    getPaginatedData().map((e) => (
                      <tr key={e.payment_index}>
                        <td>{e.user_name}</td>
                        <td>{e.phone_no}</td>
                        <td>{moment(e.created_at).format("MMM Do, YYYY")}</td>
                        <td
                          style={{
                            color: stateColor[e.state]
                              ? stateColor[e.state]
                              : "red",
                          }}
                        >
                          {e.state}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <th colSpan="5">No Data Found!!</th>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <TablePagination
                  goToPreviousPage={goToPreviousPage}
                  goToNextPage={goToNextPage}
                  currentPage={currentPage}
                  pages={pages}
                  changePage={changePage}
                  upper={pagination.upperPageBound}
                  lower={pagination.lowerPageBound}
                />
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PremiumAnalytics;
