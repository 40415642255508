import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

const Details = ({ label, description }) => {
  return (
    <Col sm="12" style={{ display: "flex", justifyContent: "space-between" }}>
      <span style={{fontWeight: '620'}} className="description pt-2">{label}</span>
      <span style={{textTransform: 'capitalize'}} className="description pt-2">{description}</span>
    </Col>
  );
};
export default Details;
