import React from "react";
import {
  Modal,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  ListGroupItem,
  Badge,
  ListGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from "moment";
const AddVersion = ({
  heading,
  isOpen,
  data,
  onClose,
  handleAdd,
  onDataChange,
  loading,
}) => {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => onClose("addDialog")}
    >
      <div className="modal-header">
        <h2 className="modal-title" id="modal-title-default">
          {heading}
        </h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onClose("addDialog")}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  id="exampleFormControlInput1"
                  placeholder="Enter Android Version"
                  type="text"
                  name="android_version"
                  onChange={(e) => onDataChange(e)}
                  value={data.android_version}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  id="exampleFormControlInput1"
                  placeholder="Enter IOS Version"
                  type="text"
                  name="ios_version"
                  onChange={(e) => onDataChange(e)}
                  value={data.ios_version}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="primary"
          outline
          data-dismiss="modal"
          type="button"
          onClick={() => onClose("addDialog")}
        >
          Close
        </Button>
        <Button color="primary" type="button" onClick={handleAdd}>
          {loading ? `Adding..` : `Add`}
        </Button>
      </div>
    </Modal>
  );
};

export default AddVersion;
