import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "reactstrap";

function ReporterDetialsDialog({ isOpen, toggleModal, data }) {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={toggleModal}
      //   backdrop="static"
    >
      <div className="modal-header">
        <h3 className="modal-title">Reporters Details</h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggleModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body pt-2 pb-2 pl-4 pr-4">
        <div className="d-flex">
          <table style={{ width: "100%", border: "1px solid #525f7f" }}>
            <thead style={{ border: "1px solid #525f7f" }}>
              <tr>
                <th style={{ border: "1px solid #525f7f" }}>
                  Reason for report
                </th>
                <th style={{ border: "1px solid #525f7f" }}>Reported at</th>
                <th style={{ border: "1px solid #525f7f" }}>Reporter Name</th>
              </tr>
            </thead>
            <tbody>
              {data && data.reported_user_data.length >= 1
                ? data.reported_user_data.map((reportedUser, index) => (
                    <tr key={index} style={{ border: "1px solid #525f7f" }}>
                      <td
                        dangerouslySetInnerHTML={{
                          __html: reportedUser.reason_to_report.join(",<br />"),
                        }}
                        style={{ border: "1px solid #525f7f" }}
                      ></td>
                      <td
                        style={{ border: "1px solid #525f7f" }}
                        dangerouslySetInnerHTML={{
                          __html: reportedUser.reported_at.join("<br />"),
                        }}
                      ></td>
                      <td
                        style={{ border: "1px solid #525f7f" }}
                        dangerouslySetInnerHTML={{
                          __html: reportedUser.reporter_name.join("<br />"),
                        }}
                      ></td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          color="primary"
          outline
          data-dismiss="modal"
          type="button"
          onClick={toggleModal}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
}

export default ReporterDetialsDialog;
