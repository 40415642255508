/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import UserProfile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import OldLogin from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";

import Login from "pages/Login";
import UserRequests from "pages/UserRequests";
import Users from "pages/Users";
import DeleteUserRequests from "pages/DeleteUserRequests";
import DeclinedUsers from "pages/DeclinedUsers";
import ViewUserDetails from "pages/ViewUserDetails";
import Profile from "pages/Profile";
import AddUser from "pages/AddUser";
import Dashboard from "pages/Dashboard";
import ForgotPassword from "pages/ForgotPassword";
import ForgotPasswordPage from "pages/ForgotPasswordPage";
import PremiumFeature from "pages/PremiumFeature";
import VersionControl from "pages/VersionControl";
import DailyPanchanga from "pages/DailyPanchanga";
import VendorAds from "pages/VendorAds";
import ExpiredVendorAds from "pages/ExpiredVendorAds";
import BlockedUsers from "pages/BlockedUsers";
import ReportedUsers from "pages/ReportedUsers";
import WhoAcceptedWhom from "pages/WhoAcceptedWhom";
import ViewAllDeletedUsers from "pages/ViewAllDeletedUsers";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    visible: false,
  },
  {
    path: "/requests",
    name: "User Requests",
    icon: "fa fa-calendar text-orange",
    component: UserRequests,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/users",
    name: "Users",
    icon: "fa fa-users text-yellow",
    component: Users,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/who-accepted-whom",
    name: "Accepted Matches",
    icon: "fa fa-users text-yellow",
    component: WhoAcceptedWhom,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/delete-requests",
    name: "Delete User Requests",
    icon: "fa fa-trash",
    component: DeleteUserRequests,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/view-all-deleted-users",
    name: "View All Deleted Users",
    icon: "fa fa-trash",
    component: ViewAllDeletedUsers,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/blocked_users",
    name: "Blocked Users",
    icon: "fa fa-ban text-orange",
    component: BlockedUsers,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/reported_users_profiles",
    name: "Reported Users",
    icon: "fa fa-flag",
    component: ReportedUsers,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/daily_panchanga",
    name: "Daily Panchanga",
    icon: "fa fa-calendar text-orange",
    component: DailyPanchanga,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/vendor_ads",
    name: "Vendor ads",
    icon: "fa fa-audio-description text-orange",
    component: VendorAds,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/expired_vendor_ads",
    name: "Expired Vendor ads",
    icon: "fa fa-audio-description text-orange",
    component: ExpiredVendorAds,
    layout: "/admin",
    visible: true,
  },
  // {
  //   path: "/declined-users",
  //   name: "Rejected Users",
  //   icon: "fa fa-times-circle text-danger",
  //   component: DeclinedUsers,
  //   layout: "/admin",
  //   visible: true,
  // },
  {
    path: "/view-user/:id",
    name: "",
    icon: "ni ni-tv-2 text-primary",
    component: ViewUserDetails,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    icon: "ni ni-single-02 text-yellow",
    component: ForgotPassword,
    layout: "/auth",
    visible: false,
  },
  {
    path: "/forgotpassword/:code",
    name: "Forgot Password Page",
    icon: "ni ni-single-02 text-yellow",
    component: ForgotPasswordPage,
    layout: "/auth",
    visible: false,
  },
  {
    path: "/premium-analytics",
    name: "Premium Analytics",
    icon: "ni ni-chart-bar-32 text-cyan",
    component: PremiumFeature,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/version-control",
    name: "Version Control",
    icon: "ni ni-ui-04 text-green",
    component: VersionControl,
    layout: "/admin",
    visible: true,
  },
  // {
  //   path: "/add-user",
  //   name: "Add User",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: AddUser,
  //   layout: "/admin",

  // },
  // {
  //   path: "/index",
  //   name: "OldDashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: UserProfile,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin",
  // },
  // {
  //   path: "/old-login",
  //   name: "OldLogin",
  //   icon: "ni ni-key-25 text-info",
  //   component: OldLogin,
  //   layout: "/auth",
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  // },
];
export default routes;
