import React from "react";
import { Button, Modal } from "reactstrap";

function ModalDialog({
  isOpen,
  toggleModal,
  type,
  data,
  handleBlockUser,
  handleUnblockUser,
}) {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={toggleModal}
    >
      <div className="modal-header">
        <h3 className="modal-title">
          {type == "block" ? `Block the user` : `Unblock the user`}
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggleModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body pt-2 pb-2 pl-4 pr-4">
        <p className="m-0">{`Are you sure you want to ${type} this user?`}</p>
      </div>
      <div className="modal-footer">
        <Button
          color={type === "block" ? "danger" : "success"}
          type="button"
          onClick={type == "block" ? handleBlockUser : handleUnblockUser}
        >
          {type == "block" ? `Block` : `Unblock`}
        </Button>
        <Button
          color={type === "block" ? "success" : "danger"}
          data-dismiss="modal"
          type="button"
          onClick={toggleModal}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDialog;
