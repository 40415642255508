/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
// reactstrap components
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import TablePagination from "components/TablePagination";
import ModalDialog from "./ModalDialog";
import {
  getInitialUserRequests,
  getPageByPageUserRequests,
  acceptUserRequest,
  rejectUserRequest,
  getPendingSearchFilter,
} from "services/userService";
import { Link, useHistory } from "react-router-dom";

const UserRequests = () => {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [respondData, setRespondData] = useState({});
  const [state, setState] = useState({});
  const [modal, setModal] = useState({
    acceptModal: false,
    rejectModal: false,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    upperPageBound: 3,
    lowerPageBound: 0,
    pageBound: 3,
    rowsPerPage: 10,
    count: 0,
  });
  const history = useHistory();

  useEffect(() => {
    const loginTime = localStorage.getItem("expirationTime");
    const elapsedTime = Date.now() - loginTime;

    if (elapsedTime > 24 * 60 * 60 * 1000) {
      // Check if 24 hours have elapsed
      localStorage.removeItem("token");
      localStorage.removeItem("expirationTime");
      history.push("/login");
    }

    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      let res = await getInitialUserRequests();
      pagination.count = res.data.size;
      let tempUser = {};
      res.data.data.map(
        ({ user_id }) => (tempUser["check" + user_id] = "Initial")
      );
      setState(tempUser);
      setUsers(res.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      // addToast(err.response.data.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  };

  const btnPrevClick = (e) => {
    if ((pagination.page - 1) % pagination.pageBound === 0) {
      pagination.upperPageBound =
        pagination.upperPageBound - pagination.pageBound;
      pagination.lowerPageBound =
        pagination.lowerPageBound - pagination.pageBound;
    }
    handleChangePage(e, pagination.page - 1);
  };
  const btnNextClick = (e) => {
    if (pagination.page + 1 > pagination.upperPageBound) {
      pagination.upperPageBound =
        pagination.upperPageBound + pagination.pageBound;
      pagination.lowerPageBound =
        pagination.lowerPageBound + pagination.pageBound;
    }
    handleChangePage(e, pagination.page + 1);
  };

  const handleChangePage = async (e, newPage) => {
    pagination.page = newPage;
    await fetchPageByPageRequests(newPage);
  };
  const fetchPageByPageRequests = async (page) => {
    try {
      let res = await getPageByPageUserRequests(page);
      let tempUser = {};
      res.data.data.map(
        ({ user_id }) => (tempUser["check" + user_id] = "Initial")
      );
      setState(tempUser);
      setUsers(res.data.data);
    } catch (err) {
      console.log(err);
      // addToast(err.response.data.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  };

  const toggleModal = (e, dialog, data) => {
    setRespondData(data);
    setModal({ ...modal, [dialog]: !modal[dialog] });
  };
  const closeModal = (e, dialog) => {
    setModal({ ...modal, [dialog]: !modal[dialog] });
  };
  const handleAcceptRequest = async () => {
    try {
      let res = await acceptUserRequest(respondData.user_id);
      if (res.status == 200) {
        setState({ ...state, ["check" + respondData.user_id]: "Accepted" });
        addToast("Accepted successfully!!", {
          appearance: "success",
          autoDismiss: true,
        });
        setModal({ ...modal, acceptModal: false });
        // fetchPageByPageRequests(pagination.page);
      }
    } catch (err) {
      console.log(err);
      // addToast(err.response.data.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  };

  const handleRejectRequest = async () => {
    try {
      let res = await rejectUserRequest(respondData.user_id);
      if (res.status == 200) {
        setState({ ...state, ["check" + respondData.user_id]: "Rejected" });
        addToast("Rejected successfully!!", {
          appearance: "success",
          autoDismiss: true,
        });
        setModal({ ...modal, rejectModal: false });
        // fetchInitialData();
      }
    } catch (err) {
      console.log(err);
      // addToast(err.response.data.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  };

  const handleSearch = async (e) => {
    setSearch(e.target.value);
    // try {
    //   if (e.target.value.length == 0 || e.target.value == "") {
    //     fetchPageByPageRequests(pagination.page);
    //   } else {
    //     let res = await getPendingSearchFilter(e.target.value);
    //     let tempUser = {};
    //     res.data.data.map(({ user_id }) => tempUser['check'+user_id] = "Initial");
    //     setState(tempUser);
    //     setUsers(res.data.data);
    //   }
    // } catch (err) {
    //   addToast(err.response.data.message, {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    // }
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex align-items-center justify-content-between flex-md-row flex-column">
                <h3 className="mb-0">User Requests</h3>
                {/* <Form>
                  <InputGroup className="input-group-alternative mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="form-control-alternative"
                      placeholder="Search by name"
                      type="text"
                      value={search}
                      onChange={handleSearch}
                    />
                  </InputGroup>
                </Form> */}
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">View Details</th>
                    <th scope="col">Respond</th>
                  </tr>
                </thead>
                <tbody>
                  {users.length > 0 ? (
                    users.map((user) => (
                      <tr key={user.user_id}>
                        <td>{user.username}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>
                          <Link to={`/admin/view-user/${user.user_id}`}>
                            View Details
                          </Link>
                        </td>
                        <td>
                          {state["check" + user.user_id] === "Initial" && (
                            <Button
                              color="success"
                              type="button"
                              onClick={(e) =>
                                toggleModal(e, "acceptModal", user)
                              }
                            >
                              Accept
                            </Button>
                          )}
                          {state["check" + user.user_id] === "Initial" && (
                            <Button
                              color="danger"
                              type="button"
                              onClick={(e) =>
                                toggleModal(e, "rejectModal", user)
                              }
                            >
                              Reject
                            </Button>
                          )}
                          {state["check" + user.user_id] !== "Initial" && (
                            <Button
                              color={
                                state["check" + user.user_id] === "Rejected"
                                  ? "danger"
                                  : "success"
                              }
                              type="button"
                              disabled={true}
                            >
                              {state["check" + user.user_id]}
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <th colSpan="5">
                        {loading ? "Loading..." : "No Data Found!!"}
                      </th>
                    </tr>
                  )}
                </tbody>
              </Table>
              {search.length < 1 && (
                <CardFooter className="py-4">
                  <TablePagination
                    upper={pagination.upperPageBound}
                    lower={pagination.lowerPageBound}
                    page={pagination.page}
                    btnNextClick={btnNextClick}
                    btnPrevClick={btnPrevClick}
                    count={pagination.count}
                    rowsPerPage={pagination.rowsPerPage}
                    handleChangePage={handleChangePage}
                  />
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>

        <ModalDialog
          isOpen={modal.acceptModal}
          toggleModal={(e) => closeModal(e, "acceptModal")}
          type="accept"
          data={respondData}
          handleAcceptRequest={handleAcceptRequest}
        />
        <ModalDialog
          isOpen={modal.rejectModal}
          toggleModal={(e) => closeModal(e, "rejectModal")}
          type="reject"
          data={respondData}
          handleRejectRequest={handleRejectRequest}
        />
      </Container>
    </>
  );
};

export default UserRequests;
