import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Row,
  Table,
} from "reactstrap";
import { useHistory, Link } from "react-router-dom";
import { getWhoAcceptedWhomList } from "services/userService";
import { useToasts } from "react-toast-notifications";
import TablePagination from "components/TablePagination";

function WhoAcceptedWhom() {
  const { addToast } = useToasts();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const loginTime = localStorage.getItem("expirationTime");
    const elapsedTime = Date.now() - loginTime;

    if (elapsedTime > 24 * 60 * 60 * 1000) {
      // Check if 24 hours have elapsed
      localStorage.removeItem("token");
      localStorage.removeItem("expirationTime");
      history.push("/login");
    }

    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      let res = await getWhoAcceptedWhomList();
      //   pagination.count = res.data.size;
      setLoading(false);
      if (res.data.responseCode === 200) {
        setUsers(res.data.responseData);
      } else {
        setUsers([]);
        addToast(res.data.responseMessage, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (err) {
      setLoading(false);
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex align-items-center justify-content-between flex-md-row flex-column">
                <h3 className="mb-0">Users List</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Request by</th>
                    <th scope="col">Request sent on</th>
                    <th scope="col">View Details</th>
                    <th scope="col">Accepted by</th>
                    <th scope="col">Accepted on</th>
                    <th scope="col">View Details</th>
                  </tr>
                </thead>
                <tbody>
                  {users && users.length >= 1 ? (
                    users.length > 0 ? (
                      users.map((user) => (
                        <tr key={user.interest_id}>
                          <td
                            className="d-flex align-items-center"
                            style={{ gap: "5px" }}
                          >
                            <img
                              src={user.request_by.profile}
                              alt="ahm"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "contain",
                              }}
                              className="rounded-circle"
                            />
                            <span className="d-flex flex-column">
                              <span>{user.request_by.username}</span>
                              <span style={{ color: "#9aa2b2" }}>
                                {user.request_by.email}
                              </span>
                            </span>
                          </td>
                          <td>{user.sent_on}</td>
                          <td>
                            <Link
                              to={`/admin/view-user/${user.request_by.user_id}`}
                            >
                              View Details
                            </Link>
                          </td>
                          <td
                            className="d-flex align-items-center"
                            style={{ gap: "5px" }}
                          >
                            <img
                              src={user.accepted_by.profile}
                              alt="ahm"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "contain",
                              }}
                              className="rounded-circle"
                            />
                            <span className="d-flex flex-column">
                              <span>{user.accepted_by.username}</span>
                              <span style={{ color: "#9aa2b2" }}>
                                {user.accepted_by.email}
                              </span>
                            </span>
                          </td>
                          <td>{user.accepted_on}</td>
                          <td>
                            <Link
                              to={`/admin/view-user/${user.accepted_by.user_id}`}
                            >
                              View Details
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <th colSpan="5">
                          {loading ? "Loading..." : "No Data Found!!"}
                        </th>
                      </tr>
                    )
                  ) : (
                    <tr className="text-center">
                      <th colSpan="5">
                        {loading ? "Loading..." : "No Data Found!!"}
                      </th>
                    </tr>
                  )}
                </tbody>
              </Table>
              {/* {search.length < 1 && (
                <CardFooter className="py-4">
                  <TablePagination
                    upper={pagination.upperPageBound}
                    lower={pagination.lowerPageBound}
                    page={pagination.page}
                    btnNextClick={btnNextClick}
                    btnPrevClick={btnPrevClick}
                    count={pagination.count}
                    rowsPerPage={pagination.rowsPerPage}
                    handleChangePage={handleChangePage}
                  />
                </CardFooter>
              )} */}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default WhoAcceptedWhom;
