import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import Details from "./Details";

const UserCard = ({data, personal, countryList}) => {
  // console.log('personal = ', personal)
  return (
    <Card className="bg-secondary shadow">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col>
            <h6 className="heading-small">Personal Details</h6>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Details label={`Name`} description={personal.username ? personal.username : `-`} />
          <Details label={`Gender`} description={personal.gender ? personal.gender :`-`} />
          <Details label={`Date of birth`} description={personal.dob? personal.dob :`-`} />
          <Details label={`Age`} description={personal.age? personal.age :`-`} />
          <Details label={`Height`} description={personal.height ? personal.height :`-`} />
          <Details label={`Weight`} description={personal.weight ? personal.weight :`-`} />
          <Details label={`Physical Status`} description={personal.physical ? personal.physical : `-`} />
          <Details label={`Country`} description={personal.country ? countryList[personal.country] : `-`} />
          <Details label={`State`} description={personal.state ? personal.state:'-'} />
          <Details label={`City`} description={personal.city ? personal.city : `-`} />
          <Details label={`Mother toungue`} description={personal.mother_tongue? personal.mother_tongue:'-'} />
          <Details label={`Marital status`} description={personal.marital ? personal.marital :'-'} />
          <Details label={`Caste`} description={personal.caste ? personal.caste :`-`} />
          <Details label={`Manglik`} description={personal.manglik ? personal.manglik :'-'} />
          <Details label={`Willing to marry from other caste`} description={personal.intercaste ? personal.intercaste :'-'} />
        </Row>
      </CardBody>
    </Card>
  );
};
export default UserCard;
