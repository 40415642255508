import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import ModalImage from "react-modal-image";
import AddVendorAds from "./AddVendorAds";
import "./Style.css";
import UpdateVendorAds from "./UpdateVendorAds";
import axios from "axios";
import { url, ngrok } from "../../config.json";
import { useToasts } from "react-toast-notifications";
import DeleteVendorAd from "./DeleteVendorAd";
import Switch from "@material-ui/core/Switch";
import ViewVendorAds from "./ViewVendorAds";
import { useHistory } from "react-router-dom";
import ManageCategory from "./ManageCategory";

const Paginationn = ({ items, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(items.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, items.length);
  const [loading, setLoading] = useState({
    list: false,
    add: false,
    update: false,
    delete: false,
  });
  const [dialogs, setDialogs] = useState({
    addDialog: false,
    deleteDialog: false,
    updateDialog: false,
    viewDialog: false,
  });
  const [type, setType] = useState("");
  const [respondData, setRespondData] = useState({
    title: "",
    description: "",
    mobile: "",
    email: "",
    address: "",
    image: "",
    id: "",
    isActive: null,
  });
  const [viewData, setViewData] = useState({
    title: "",
    description: "",
    mobile: "",
    email: "",
    address: "",
    image: "",
    expiresIn: "",
    category: "",
    createdAt: "",
  });
  const { addToast } = useToasts();
  const [preImg, setpreImg] = useState("");
  const characterLimit = 35;
  const [statusSelectedOption, setStatusSelectedOption] = useState("");
  const [isCategoryList, setIsCategoryList] = useState([]);
  const access_token = localStorage.getItem("token");
  const [isCatData, setIsCatData] = useState({ category: "" });

  useEffect(() => {
    axios
      .get(`${url}/api/vendor_ads/vendor_ads_category`, {
        headers: {
          "x-access-token": access_token,
        },
      })
      .then((res) => {
        // setIsCategoryList(
        //   res.data.responseData.map((item) => {
        //     return { value: item.vendor_ads_category_id, label: item.category };
        //   })
        // );
        setIsCategoryList([
          ...res.data.responseData.filter((item) => item?.status === 1),
          { vendor_ads_category_id: "Other", category: "Other" },
        ]);
      });
  }, [isCategoryList]);

  const handleOpenDialog = (e, dialog, data) => {
    setType(dialog);
    if (data) {
      setRespondData({
        title: data.title,
        description: data.description,
        mobile: data.mobile,
        email: data.email,
        address: data.address,
        image: data.image,
        id: data.vendor_ads_id,
        isActive: data.isVisible === 1 ? true : false,
      });
      setViewData({
        title: data.title,
        description: data.description,
        mobile: data.mobile,
        email: data.email,
        address: data.address,
        image: data.image,
        expiresIn: data.expiresIn,
        category: data.category,
        createdAt: data.createdAt,
      });
      // console.log(data);
      setStatusSelectedOption({ label: data.category });
      setpreImg(data.image);
    }
    setDialogs({ ...dialogs, [dialog]: true });
  };

  const handleNextPage = () => {
    if (items.length === 0) {
      setCurrentPage(currentPage);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleClose = (dialog) => {
    setDialogs({ ...dialogs, [dialog]: false });
  };

  const handleRespondDataChange = (e) => {
    setRespondData({ ...respondData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    const fileSize = selectedFile.size / 1024 / 1024; // Convert bytes to megabytes
    if (fileSize > 2) {
      alert(
        "File size is too large. Maximum size is 2MB. Please try any other image."
      );
      document.getElementById("image").value = "";
      return;
    }
    setRespondData({ ...respondData, image: selectedFile });
    setpreImg(URL.createObjectURL(selectedFile));
  };

  const handleMobileChange = (e) => {
    setRespondData({ ...respondData, mobile: e });
  };

  const handleCategoryChange = (e) => {
    setStatusSelectedOption(e);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("title", respondData.title);
    formData.append("description", respondData.description);
    formData.append("mobile", respondData.mobile);
    formData.append("email", respondData.email);
    formData.append("address", respondData.address);
    formData.append("image", respondData.image);
    formData.append(
      "category",
      statusSelectedOption.label === "Other"
        ? isCatData.category
        : statusSelectedOption.label
    );

    axios
      .put(
        `${url}/api/vendor_ads/update_vendor_ads/${respondData.id}`,
        formData,
        { headers: { "x-access-token": access_token } }
      )
      .then((res) => {
        if (res.data.responseCode === 200) {
          setDialogs({ updateDialog: false });
          addToast("Vendor ad updated successfully...", {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast(res.data.responseMessage, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((err) => {
        addToast(err, {
          appearance: "error",
          autoDismiss: true,
        });
      });

    axios
      .post(`${url}/api/vendor_ads/add_ads_cat`, isCatData, {
        headers: {
          "x-access-token": access_token,
        },
      })
      .then((res) => {
        setIsCatData({ ...isCatData, category: "" });
      })
      .catch((err) => {
        addToast(err, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleDelete = () => {
    axios
      .delete(`${url}/api/vendor_ads/delete_vendor_ads/${respondData.id}`, {
        headers: { "x-access-token": access_token },
      })
      .then((res) => {
        setDialogs({ deleteDialog: false });
        addToast("Vendor ad deleted successfully...", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((e) => {
        addToast(e, { appearance: "error", autoDismiss: true });
      });
  };

  const handleStatusChange = async (e, data) => {
    let statusFlag = e.target.checked === true ? 1 : 2;

    await axios
      .put(
        `${url}/api/vendor_ads/update_vendor_ads/${data.vendor_ads_id}`,
        {
          title: data.title,
          description: data.description,
          mobile: data.mobile,
          email: data.email,
          address: data.address,
          image: data.image,
          isVisible: statusFlag,
          category: data.category,
        },
        { headers: { "x-access-token": access_token } }
      )
      .then((res) => {
        if (res.data.responseCode === 200) {
          addToast(
            e.target.checked === false
              ? "Vendor ad status enabled successfully"
              : "Vendor ad status disabled successfully",
            {
              appearance: "success",
              autoDismiss: true,
            }
          );
        } else {
          addToast(res.data.responseMessage, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((err) => {
        addToast(err, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <div>
      <Table className="align-items-center table-flush" responsive>
        <thead style={{ backgroundColor: "#f6f9fc", color: "#8898aa" }}>
          <tr>
            <th className="text-left" scope="col">
              Title
            </th>
            <th className="text-left" scope="col">
              Category
            </th>
            <th className="text-center" scope="col">
              Created
            </th>
            <th className="text-center" scope="col">
              Expires In
            </th>
            <th className="text-center" scope="col">
              View Details
            </th>
            <th className="text-center" scope="col">
              Status
            </th>
            <th className="text-center" scope="col">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {loading.list ? (
            <tr>
              <td colSpan={6} style={{ width: "100%", textAlign: "center" }}>
                <Spinner type="grow" color="primary" />
              </td>
            </tr>
          ) : currentItems.length > 0 ? (
            currentItems.map((e, i) => (
              <tr key={e.vendor_ads_id}>
                <td className="text-left">{e.title}</td>
                <td className="text-left">{e.category}</td>
                <td className="text-center">{e.createdAt}</td>
                <td className="text-center">
                  {e.expiresIn <= "45 days" ? (
                    <p
                      style={{
                        backgroundColor: "#ff0000",
                        padding: "4px 15px",
                        fontSize: "14px",
                        borderRadius: "50px",
                      }}
                    >
                      {e.expiresIn}
                    </p>
                  ) : (
                    <p
                      style={{
                        backgroundColor: "#00ff00",
                        padding: "4px 15px",
                        fontSize: "14px",
                        borderRadius: "50px",
                        color: "black",
                        fontWeight: "500",
                      }}
                    >
                      {e.expiresIn}
                    </p>
                  )}
                </td>
                <td className="text-center">
                  <p
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      color: "#5e72e4",
                    }}
                    onClick={(event) =>
                      handleOpenDialog(event, "viewDialog", e)
                    }
                  >
                    View Details
                  </p>
                </td>
                <td className="text-center">
                  <Switch
                    color="primary"
                    checked={e.isVisible === 1 ? true : false}
                    onChange={(event) => handleStatusChange(event, e)}
                    name="status"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </td>
                <td className="text-center">
                  <i
                    className="fa fa-edit text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={(event) =>
                      handleOpenDialog(event, "updateDialog", e)
                    }
                  />
                  <i
                    className="fa fa-trash text-danger"
                    style={{ cursor: "pointer", marginLeft: "50px" }}
                    onClick={(event) =>
                      handleOpenDialog(event, "deleteDialog", e)
                    }
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr className="text-center">
              <th colSpan="11">No Data Found!!</th>
            </tr>
          )}
        </tbody>
      </Table>
      <Container className="justify-content-end align-items-center d-flex mr-1 mt-2 mb-2">
        <Container className="d-flex align-items-center justify-content-end">
          <p style={{ marginBottom: "0", fontWeight: "500" }}>
            {startIndex}-{endIndex} /{items.length}
          </p>
        </Container>
        <Button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          style={{ borderRadius: "50px" }}
          className="bg-primary text-white"
        >
          <i className="fas fa-angle-left" />
        </Button>
        <Button
          onClick={handleNextPage}
          style={{ borderRadius: "50px" }}
          disabled={currentPage === totalPages}
          className="bg-primary text-white"
        >
          <i className="fas fa-angle-right" />
        </Button>
      </Container>

      <UpdateVendorAds
        heading={"Update Vendor Ad"}
        isOpen={dialogs.updateDialog}
        data={respondData}
        onClose={handleClose}
        handleUpdate={handleUpdate}
        onDataChange={handleRespondDataChange}
        onPhoneChange={handleMobileChange}
        loading={loading.update}
        handleImageChange={handleImageChange}
        preImg={preImg}
        characterLimit={characterLimit}
        statusOptions={isCategoryList}
        setStatusSelectedOption={setStatusSelectedOption}
        statusSelectedOption={statusSelectedOption}
        handleCategoryChange={handleCategoryChange}
        isCatData={isCatData}
        setIsCatData={setIsCatData}
      />
      <DeleteVendorAd
        heading={"Delete Vendor Ad"}
        isOpen={dialogs.deleteDialog}
        onClose={handleClose}
        loading={loading.delete}
        handleDelete={handleDelete}
      />
      <ViewVendorAds
        isOpen={dialogs.viewDialog}
        heading={"View Details"}
        onClose={handleClose}
        viewData={viewData}
      />
    </div>
  );
};

function Index() {
  const [vendorAdsList, setVendorAdsList] = useState([]);
  // const [imageUrl, setImageUrl] = useState();
  const [type, setType] = useState("");
  const [dialogs, setDialogs] = useState({
    addDialog: false,
    deleteDialog: false,
    updateDialog: false,
    categoryDialog: false,
  });
  const [loading, setLoading] = useState({
    list: false,
    add: false,
    update: false,
    delete: false,
  });
  const { addToast } = useToasts();
  const n = 80000000000000;
  const access_token = localStorage.getItem("token");
  const history = useHistory();
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    autoLogout();

    axios
      .get(`${url}/api/vendor_ads/list_vendor_ads?limit=${n}`, {
        headers: {
          "x-access-token": access_token,
        },
      })
      .then((res) => {
        if (res.data.responseCode === 200) {
          setVendorAdsList(res.data.responseData);
          setLoading({ ...loading, list: false });
        } else {
          addToast(res.data.responseMessage, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((err) => {
        addToast(err.response.data.responseMessage, {
          appearance: "error",
          autoDismiss: true,
        });
      });

    axios
      .get(`${url}/api/vendor_ads/vendor_ads_category`, {
        headers: {
          "x-access-token": access_token,
        },
      })
      .then((res) => {
        setCategoryData(res.data.responseData);
      })
      .catch((err) => {
        addToast(err, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  }, [vendorAdsList]);

  const autoLogout = () => {
    const loginTime = localStorage.getItem("expirationTime");
    const elapsedTime = Date.now() - loginTime;

    if (elapsedTime > 24 * 60 * 60 * 1000) {
      // Check if 24 hours have elapsed
      localStorage.removeItem("token");
      localStorage.removeItem("expirationTime");
      history.push("/login");
    }
  };

  const handleOpenDialog = (e, dialog, data) => {
    setType(dialog);
    // if (data) {
    //   setRespondData(data);
    // }
    setDialogs({ ...dialogs, [dialog]: true });
  };

  const handleClose = (dialog) => {
    setDialogs({ ...dialogs, [dialog]: false });
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className=" shadow">
              <CardHeader className="bg-transparent border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Vendor Ads</h3>
                  </div>
                  <div className="col text-right">
                    <div className="row justify-content-end align-items-center">
                      <div
                        className="p-2 bg-primary text-white rounded shadow row align-items-center"
                        style={{ cursor: "pointer", marginRight: "10px" }}
                        onClick={(e) => handleOpenDialog(e, "categoryDialog")}
                      >
                        <i
                          class="fas fa-list"
                          style={{ fontSize: 10, marginRight: "5px" }}
                        ></i>
                        Manage Category
                      </div>
                      <div
                        className="icon icon-shape bg-primary text-white rounded-circle shadow"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => handleOpenDialog(e, "addDialog")}
                      >
                        <i className="fas fa-plus" style={{ fontSize: 10 }}></i>
                      </div>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <Paginationn
                items={vendorAdsList}
                itemsPerPage={5}
                // imageUrl={imageUrl}
              />
            </Card>
          </Col>
        </Row>
      </Container>

      <AddVendorAds
        heading={"Add New Vendor ad"}
        isOpen={dialogs.addDialog}
        isModalClose={() => setDialogs({ addDialog: false })}
        // data={data}
        onClose={handleClose}
        // handleAdd={handleAdd}
        // onDataChange={handleDataChange}
        loading={loading.add}
      />

      <ManageCategory
        heading={"Manage Category"}
        isOpen={dialogs.categoryDialog}
        isModalClose={() => setDialogs({ categoryDialog: false })}
        onClose={handleClose}
        currentItems={categoryData}
      />
    </>
  );
}

export default Index;
