import Header from "components/Headers/Header";
import TablePagination from "components/TablePagination";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
} from "reactstrap";
import { getReportedUserRequest } from "services/userService";
import ModalDialog from "./ModalDialog";
import { blockUser } from "services/userService";
import axios from "axios";
import ReporterDetialsDialog from "./ReporterDetailsDialog";
import { acceptUserRequest } from "services/userService";
import DeleteDialog from "./DeleteDialog";
import { getAcceptUserDeleteRequest } from "services/userService";

function Index() {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    upperPageBound: 3,
    lowerPageBound: 0,
    pageBound: 3,
    rowsPerPage: 10,
    count: 0,
  });
  const history = useHistory();
  const { addToast } = useToasts();
  const [modal, setModal] = useState({
    blockModal: false,
    unblockModal: false,
    deleteAccountModal: false,
    reporterDetailsModal: false,
  });
  const [isReporterData, setIsReporterData] = useState();
  const [reducedValue, forceUpdate] = React.useReducer((x) => x + 1, 0);

  useEffect(() => {
    const loginTime = localStorage.getItem("expirationTime");
    const elapsedTime = Date.now() - loginTime;

    if (elapsedTime > 24 * 60 * 60 * 1000) {
      // Check if 24 hours have elapsed
      localStorage.removeItem("token");
      localStorage.removeItem("expirationTime");
      history.push("/login");
    }

    fetchInitialData();
  }, [reducedValue]);

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      let res = await getReportedUserRequest();
      pagination.count = res.data.size;
      setUsers(res.data.responseData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const btnPrevClick = (e) => {
    if ((pagination.page - 1) % pagination.pageBound === 0) {
      pagination.upperPageBound =
        pagination.upperPageBound - pagination.pageBound;
      pagination.lowerPageBound =
        pagination.lowerPageBound - pagination.pageBound;
    }
    handleChangePage(e, pagination.page - 1);
  };
  const btnNextClick = (e) => {
    if (pagination.page + 1 > pagination.upperPageBound) {
      pagination.upperPageBound =
        pagination.upperPageBound + pagination.pageBound;
      pagination.lowerPageBound =
        pagination.lowerPageBound + pagination.pageBound;
    }
    handleChangePage(e, pagination.page + 1);
  };

  const handleChangePage = async (e, newPage) => {
    pagination.page = newPage;
    // await fetchPageByPageRequests(newPage);
  };

  const toggleModal = (e, dialog, data) => {
    setModal({ ...modal, [dialog]: !modal[dialog] });
    setIsReporterData(data);
  };

  const closeModal = (e, dialog) => {
    setModal({ ...modal, [dialog]: !modal[dialog] });
  };

  const handleBlockUser = async (e) => {
    e.preventDefault();
    try {
      let res = await blockUser(isReporterData.user_id);
      if (res.status == 200) {
        addToast("Blocked successfully!!", {
          appearance: "success",
          autoDismiss: true,
        });
        setModal({ ...modal, blockModal: false });
        // fetchPageByPageUsers(pagination.page);
        forceUpdate();
      }
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleUnblockUser = async (e) => {
    e.preventDefault();
    try {
      let res = await acceptUserRequest(isReporterData.user_id);
      if (res.status == 200) {
        addToast("Unblocked successfully!!", {
          appearance: "success",
          autoDismiss: true,
        });
        setModal({ ...modal, unblockModal: false });
        // fetchPageByPageUsers(pagination.page);
        forceUpdate();
      }
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleAcceptRequest = async () => {
    try {
      let res = await getAcceptUserDeleteRequest(isReporterData.user_id);
      if (res.status == 200) {
        // setState({ ...state, ["check" + respondData.user_id]: "Accepted" });
        addToast("Accepted successfully!!", {
          appearance: "success",
          autoDismiss: true,
        });
        setModal({ ...modal, deleteAccountModal: false });
        forceUpdate();
        // fetchPageByPageRequests(pagination.page);
      }
    } catch (err) {
      // addToast(err.response.data.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  };

  return (
    <div>
      <Header />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex align-items-center justify-content-between flex-md-row flex-column">
                <h3 className="mb-0">Reported Users List</h3>
                {/* <Form>
                  <InputGroup className="input-group-alternative mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="form-control-alternative"
                      placeholder="Search by name"
                      type="text"
                      value={search}
                      onChange={handleSearch}
                    />
                  </InputGroup>
                </Form> */}
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Reported User</th>
                    <th scope="col">Reported User Details</th>
                    <th scope="col">Total Reports</th>
                    <th scope="col">Reporter Details</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {users.length > 0 ? (
                    users.map((user) => (
                      <tr key={user.user_id}>
                        <td>{user.username}</td>
                        <td>
                          <Link to={`/admin/view-user/${user.user_id}`}>
                            View Reported User Details
                          </Link>
                        </td>
                        <td className="text-center">{user.total_reports}</td>
                        <td>
                          <Link
                            to={`#`}
                            onClick={(e) =>
                              toggleModal(e, `reporterDetailsModal`, user)
                            }
                          >
                            View Reporter Details
                          </Link>
                        </td>
                        <td>
                          <Button
                            style={{ width: "100px" }}
                            color={
                              user.status === "approved" ? "danger" : "success"
                            }
                            type="button"
                            onClick={(e) =>
                              toggleModal(
                                e,
                                user.status == "approved"
                                  ? `blockModal`
                                  : `unblockModal`,
                                user
                              )
                            }
                          >
                            {user.status == `approved` ? `Block` : `Unblock`}
                          </Button>
                          <Button
                            color="danger"
                            type="button"
                            onClick={(e) =>
                              toggleModal(e, `deleteAccountModal`, user)
                            }
                          >
                            Delete Account
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <th colSpan="6">
                        {loading ? "Loading..." : "No Data Found!!"}
                      </th>
                    </tr>
                  )}
                </tbody>
              </Table>
              {search.length < 1 && (
                <CardFooter className="py-4">
                  <TablePagination
                    upper={pagination.upperPageBound}
                    lower={pagination.lowerPageBound}
                    page={pagination.page}
                    btnNextClick={btnNextClick}
                    btnPrevClick={btnPrevClick}
                    count={pagination.count}
                    rowsPerPage={pagination.rowsPerPage}
                    handleChangePage={handleChangePage}
                  />
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      <ModalDialog
        isOpen={modal.blockModal}
        toggleModal={(e) => closeModal(e, "blockModal")}
        type="block"
        handleBlockUser={handleBlockUser}
        data={isReporterData}
      />
      <ModalDialog
        isOpen={modal.unblockModal}
        toggleModal={(e) => closeModal(e, "unblockModal")}
        type="unblock"
        handleUnblockUser={handleUnblockUser}
        data={isReporterData}
      />
      <DeleteDialog
        isOpen={modal.deleteAccountModal}
        toggleModal={(e) => closeModal(e, "deleteAccountModal")}
        type="delete"
        handleDeleteUser={handleAcceptRequest}
      />
      <ReporterDetialsDialog
        isOpen={modal.reporterDetailsModal}
        toggleModal={(e) => closeModal(e, "reporterDetailsModal")}
        data={isReporterData}
      />
    </div>
  );
}

export default Index;
