import React from "react";
import { Button, Modal } from "reactstrap";
import ModalImage from "react-modal-image";
import { url } from "../../config.json";

function ViewVendorAds({ isOpen, heading, onClose, viewData }) {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => onClose("viewDialog")}
      backdrop="static"
    >
      <div className="modal-header">
        <h2 className="modal-title" id="modal-title-default">
          {heading}
        </h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onClose("viewDialog")}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="d-flex" style={{ padding: "15px 0" }}>
          <div style={{ width: "40%" }}>Title</div>
          <div style={{ width: "60%" }}>: {viewData.title}</div>
        </div>
        <div className="d-flex" style={{ padding: "15px 0" }}>
          <div style={{ width: "40%" }}>Description</div>
          <div style={{ width: "60%" }}>: {viewData.description}</div>
        </div>
        <div className="d-flex" style={{ padding: "15px 0" }}>
          <div style={{ width: "40%" }}>Category</div>
          <div style={{ width: "60%" }}>: {viewData.category}</div>
        </div>
        <div className="d-flex" style={{ padding: "15px 0" }}>
          <div style={{ width: "40%" }}>Mobile</div>
          <div style={{ width: "60%" }}>: +{viewData.mobile}</div>
        </div>
        <div className="d-flex" style={{ padding: "15px 0" }}>
          <div style={{ width: "40%" }}>Email</div>
          <div style={{ width: "60%" }}>: {viewData.email}</div>
        </div>
        <div className="d-flex" style={{ padding: "15px 0" }}>
          <div style={{ width: "40%" }}>Address</div>
          <div style={{ width: "60%" }}>: {viewData.address}</div>
        </div>
        <div className="d-flex" style={{ padding: "15px 0" }}>
          <div style={{ width: "40%" }}>Created At</div>
          <div style={{ width: "60%" }}>:{viewData.createdAt}</div>
        </div>
        <div className="d-flex" style={{ padding: "15px 0" }}>
          <div style={{ width: "40%" }}>Expires In</div>
          <div style={{ width: "60%" }}>: {viewData.expiresIn}</div>
        </div>
        <div className="d-flex" style={{ padding: "15px 0" }}>
          <div style={{ width: "40%" }}>Image</div>
          <div style={{ width: "60%" }}>
            <ModalImage
              small={url + viewData.image}
              large={url + viewData.image}
              alt={viewData.title}
              className="imgWidth rounded"
            />
          </div>
        </div>

        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="primary"
            outline
            data-dismiss="modal"
            type="button"
            onClick={() => onClose("viewDialog")}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ViewVendorAds;
