import React from "react";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "react-phone-number-input";
import ReactSelect from "react-select";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
import "./Style.css";
import { url } from "../../config.json";

function UpdateVendorAds({
  isOpen,
  onClose,
  loading,
  heading,
  handleUpdate,
  onDataChange,
  onPhoneChange,
  data,
  handleImageChange,
  preImg,
  characterLimit,
  statusOptions,
  statusSelectedOption,
  handleCategoryChange,
  setIsCatData,
  isCatData,
}) {
  const colorStyles = {
    control: (styles) => ({
      ...styles,
      marginBottom: "0.75rem",
      marginTop: "0.25rem",
      fontSize: "0.875rem",
      boxShadow:
        "0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)",
      border: "none",
    }),
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => onClose("updateDialog")}
      backdrop="static"
    >
      <div className="modal-header">
        <h2 className="modal-title" id="modal-title-default">
          {heading}
        </h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onClose("updateDialog")}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={handleUpdate}>
          <Row>
            <Col md="12">
              <FormGroup>
                <ReactSelect
                  options={statusOptions.map((item) => {
                    return {
                      value: item.vendor_ads_category_id,
                      label: item.category,
                    };
                  })}
                  onChange={handleCategoryChange}
                  placeholder="Select the categories"
                  styles={colorStyles}
                  defaultInputValue={statusSelectedOption.label}
                />
              </FormGroup>
            </Col>
            {statusSelectedOption?.label === "Other" && (
              <Col md="12">
                <FormGroup>
                  <Input
                    className="form-control-alternative"
                    placeholder="Enter Category Name"
                    type="text"
                    name="title"
                    onChange={(e) =>
                      setIsCatData({ ...isCatData, category: e.target.value })
                    }
                    value={isCatData.category}
                  />
                </FormGroup>
              </Col>
            )}
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Vendor Ad Title"
                  type="text"
                  name="title"
                  onChange={(e) => onDataChange(e)}
                  value={data.title}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              {/* <FormGroup> */}
              <textarea
                className="form-control-alternative textareaStyle rounded"
                placeholder="Enter Description"
                type="text"
                maxLength={35}
                name="description"
                onChange={(e) => onDataChange(e)}
                value={data.description}
              />
              <Container className="d-flex justify-content-end">
                <p
                  style={{
                    marginBottom: "0",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  {data.description.length}/{characterLimit}
                </p>
              </Container>
              {/* </FormGroup> */}
            </Col>
            <Col md="12">
              <FormGroup>
                <PhoneInput
                  country={"in"}
                  onChange={onPhoneChange}
                  value={data.mobile}
                  name="mobile"
                  inputStyle={{
                    position: "unset",
                    width: "100%",
                    boxShadow:
                      "0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%)",
                    border: "none",
                  }}
                  buttonStyle={{
                    border: "none",
                  }}
                  isValid={(value, country) => {
                    if (value === country.dialCode) {
                      return true;
                    }
                    return isValidPhoneNumber("+" + value);
                  }}
                  enableSearch
                  defaultErrorMessage="Please enter valid number"
                  required
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Vendor Email"
                  type="email"
                  name="email"
                  onChange={(e) => onDataChange(e)}
                  value={data.email}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Vendor Address"
                  type="text"
                  name="address"
                  onChange={(e) => onDataChange(e)}
                  value={data.address}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  style={{ padding: "5px 13px" }}
                  className="form-control-alternative rounded"
                  placeholder="Enter Image"
                  type="file"
                  name="image"
                  id="image"
                  onChange={handleImageChange}
                  // value={data.image}
                />
                <Container style={{ textAlign: "center" }}>
                  <p
                    style={{
                      color: "#ff0000",
                      fontWeight: "500",
                      fontSize: "15px",
                      marginBottom: "0",
                    }}
                  >
                    .jpg, .png, .jpeg, .heic image formats are supported *
                  </p>
                  <p
                    style={{
                      color: "#ff0000",
                      fontWeight: "500",
                      fontSize: "15px",
                      marginBottom: "0",
                    }}
                  >
                    Maximum upload file size: 2MB *
                  </p>
                </Container>
              </FormGroup>
            </Col>
            {preImg ? (
              <Col
                md="12"
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  // src={url + preImg}
                  src={preImg.startsWith("blob") ? preImg : url + preImg}
                  alt="Preview"
                  width="100"
                  height="75"
                  className="rounded"
                />
              </Col>
            ) : null}
          </Row>
          <div className="modal-footer">
            <Button
              className="ml-auto"
              color="primary"
              outline
              data-dismiss="modal"
              type="button"
              onClick={() => onClose("updateDialog")}
            >
              Close
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={!isValidPhoneNumber("+" + data.mobile) ? true : false}
            >
              {loading ? `Updating..` : `Update`}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default UpdateVendorAds;
