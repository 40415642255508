import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  login,
  decodeToken,
  sendResetPasswordEmail,
} from "services/authServices";

const ForgotPassword = ({ history }) => {
  const { addToast } = useToasts();
  const [display, setDisplay] = useState({
    emailForm: true,
  });
  const [data, setData] = useState({
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    try {
      let res = await sendResetPasswordEmail(data.email);
      if (res.status == 200) {
        addToast(res.data.message, {
          appearance: "success",
          autoDismiss: true,
        });
        setData({ ...data, email: "" });
      }
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <h1 className="text-center mt-2">
              {display.emailForm && `Forgot Password`}
            </h1>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              {display.emailForm && (
                <>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Enter email"
                        type="text"
                        name="email"
                        value={data.email}
                        onChange={handleDataChange}
                        // autoComplete="new-email"
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="submit"
                      onClick={handleSendOtp}
                    >
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col
            xs="12"
            className="d-flex align-items-center justify-content-center"
          >
            <a className="text-light" href="/auth/login">
              <small>Login?</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default withRouter(ForgotPassword);
