/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import { useToasts } from "react-toast-notifications";

import UserHeader from "components/Headers/UserHeader.js";
import UserCard from "./UserCard";
import CareerCard from "./CareerCard";
import Details from "./Details";
import PartnerProfile from "./PartnerProfile";
import FamilyBase from "./FamilyBase";
import { getUserDetails } from "../../services/userService";
import { getCountries } from "country-state-picker";
import { url } from "../../config.json";
import { useHistory, useParams } from "react-router-dom";

const ViewUserDetails = (props) => {
  const [data, setData] = useState([]);
  const [career, setCareer] = useState({});
  const [family, setFamily] = useState({});
  const [partner, setPartner] = useState({});
  const [personal, setPersonal] = useState({});
  const [countryList, setCountryList] = React.useState({});
  const { addToast } = useToasts();
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    const loginTime = localStorage.getItem("expirationTime");
    const elapsedTime = Date.now() - loginTime;

    if (elapsedTime > 24 * 60 * 60 * 1000) {
      // Check if 24 hours have elapsed
      localStorage.removeItem("token");
      localStorage.removeItem("expirationTime");
      history.push("/login");
    }

    let tempCountry = {};
    getCountries().map(({ code, name }) => (tempCountry[code] = name));
    setCountryList(tempCountry);
    // fetchUserDetails(props.match.params.id);
    fetchUserDetails(id);
  }, []);
  //   "careerDetails":{
  //     "education":"Diploma",
  //     "occupation":"chef",
  //     "employeed_in":"india",
  //     "currency":"rupees",
  //     "annual_income":"120000",
  //     "eating":"vegeterian",
  //     "smoking":"yes",
  //     "drinking":"yes",
  //     "star":"adsadad",
  //     "rashi":"noasdad",
  //     "time_of_birth":"10:00:00",
  //     "place_of_birth":"venuzula"
  //  },
  const fetchUserDetails = async (id) => {
    try {
      let res = await getUserDetails(id);
      // console.log(res);
      setData(res.data);
      // console.log(id);
      // console.log(
      //   "res.data.personalDetails = ",
      //   res?.data?.personalDetails && res?.data?.personalDetails != null
      // );
      // alert('gg')
      // console.log("res.data = ", res.data);
      // setPersonal(res.data.personalDetails);
      // console.log("ddddddddd = ", res.data.personalDetails);
      // alert('lllllll')
      if (res?.data?.careerDetails && res?.data?.careerDetails != null) {
        setCareer(res.data.careerDetails);
      } else {
        career.education = "-";
        career.occupation = "-";
        career.employeed_in = "-";
        career.annual_income = "-";
        career.currency = "-";
        career.eating = "-";
        career.smoking = "-";
        career.drinking = "-";
        career.star = "-";
        career.rashi = "-";
        career.place_of_birth = "-";
        career.time_of_birth = "-";
      }

      if (res?.data?.careerDetails && res?.data?.careerDetails != null) {
        setFamily(res.data.familyDetails);
      } else {
        family.about_me = "-";
        family.brother = "-";
        family.family_country = "-";
        family.family_state = "-";
        family.family_city = "-";
        family.income = "-";
        family.currency = "-";
        family.sister = "-";
        family.type = "-";
        family.values = "-";
        family.status = "-";
        family.father_occupation = "-";
        family.mother_occupation = "-";
      }
      setPartner(res.data.partnerDetails);

      if (res?.data?.personalDetails && res?.data?.personalDetails != null) {
        setPersonal({ ...res.data.personalDetails, ...res.data.userDetails });
      } else {
        personal.age = "-";
        personal.caste = "-";
        personal.country = "-";
        personal.state = "-";
        personal.city = "-";
        personal.gender = "-";
        personal.dob = "-";
        personal.weight = "-";
        personal.height = "-";
        personal.intercaste = "-";
        personal.manglik = "-";
        personal.physical = "-";
        personal.marital = "-";
        personal.mother_tongue = "-";
      }
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  return (
    <>
      <UserHeader
        aboutMe={family.about_me ? family.about_me : ""}
        username={personal.username}
      />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Row>
              <Col xl="12">
                <Card className="card-profile shadow">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={
                              personal.profile_link
                                ? `${personal.profile_link}`
                                : require("../../assets/img/theme/admin.png")
                                    .default
                            }
                            // src={
                            //   require("../../assets/img/theme/team-4-800x800.jpg")
                            //     .default
                            // }
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>

                  <CardHeader className=" pt-8 pt-md-4 pb-0 pb-md-4"></CardHeader>
                  <CardBody className="pt-0 pt-md-4">
                    <Row>
                      <div className="col">
                        {/* <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                          <div>
                            <span className="heading">22</span>
                            <span className="description">Friends</span>
                          </div>
                          <div>
                            <span className="heading">10</span>
                            <span className="description">Photos</span>
                          </div>
                          <div>
                            <span className="heading">89</span>
                            <span className="description">Comments</span>
                          </div>
                        </div> */}
                      </div>
                    </Row>
                    <div className="text-center mt-md-6">
                      <h3>
                        {personal.username}
                        {/* <span className="font-weight-light">
                          {"  "}
                          {personal.age}
                        </span> */}
                      </h3>
                      <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        {personal.email}
                      </div>
                      <div className="h5">
                        <i className="ni business_briefcase-24 mr-2" />
                        {personal.phone}
                      </div>

                      {/* <hr className="my-4" /> */}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="12" className="mt-4">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white">
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="heading-small">Family Details</h6>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Details
                        label={`Family status`}
                        description={
                          family.family_status ? family.family_status : `-`
                        }
                      />
                      <Details
                        label={`Family type`}
                        description={
                          family.family_type ? family.family_type : `-`
                        }
                      />
                      <Details
                        label={`Family Values`}
                        description={
                          family.family_values ? family.family_values : `-`
                        }
                      />
                      <Details
                        label={`Father Occupation`}
                        description={
                          family.father_occupation
                            ? family.father_occupation
                            : `-`
                        }
                      />
                      <Details
                        label={`Mother Occupation`}
                        description={
                          family.mother_occupation
                            ? family.mother_occupation
                            : `-`
                        }
                      />
                      <Details
                        label={`Brother`}
                        description={family.brother ? family.brother : `-`}
                      />
                      <Details
                        label={`Sister`}
                        description={family.sister ? family.sister : `-`}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col className="order-xl-1" xl="8">
            <Row>
              <Col xl="6">
                <UserCard
                  data={data}
                  personal={personal}
                  countryList={countryList}
                />
                <FamilyBase data={family} countryList={countryList} />
              </Col>
              <Col xl="6">
                <CareerCard c={career} />
              </Col>
            </Row>
          </Col>
        </Row>
        {partner != null && (
          <Row>
            <Col xl="12">
              <PartnerProfile p={partner} countryList={countryList} />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default ViewUserDetails;
