import { http } from "./httpService";
import { apiUrl } from "../config.json";

const apiEndPointNotification = apiUrl + "payment";

const getPaymentList = (data) => {
  return http.get(`${apiEndPointNotification}/getPaymentList`, { params: data });
};

export { getPaymentList };
