import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-input-2/lib/style.css";
import "./Style.css";
import axios from "axios";
import { url, ngrok } from "../../config.json";
import { useToasts } from "react-toast-notifications";
import ReactSelect from "react-select";

function AddVendorAds({ isOpen, onClose, heading, loading, isModalClose }) {
  const [data, setData] = useState({
    title: "",
    description: "",
    mobile: "",
    email: "",
    address: "",
    image: "",
  });
  const [isPreImg, setIsPreImg] = useState();
  const { addToast } = useToasts();
  const characterLimit = 35;
  const [statusSelectedOption, setStatusSelectedOption] = useState();
  const [isCategoryList, setIsCategoryList] = useState([]);
  const access_token = localStorage.getItem("token");
  const [isCatData, setIsCatData] = useState({ category: "" });

  const colorStyles = {
    control: (styles) => ({
      ...styles,
      marginBottom: "0.75rem",
      marginTop: "0.25rem",
      fontSize: "0.875rem",
      boxShadow:
        "0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)",
      border: "none",
    }),
  };

  useEffect(() => {
    axios
      .get(`${url}/api/vendor_ads/vendor_ads_category`, {
        headers: {
          "x-access-token": access_token,
        },
      })
      .then((res) => {
        // console.log(res);
        setIsCategoryList([
          ...res.data.responseData.filter((item) => item?.status === 1),
          { vendor_ads_category_id: "Other", category: "Other", status: 1 },
        ]);
      });
  }, [isCategoryList]);

  const onDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handlePhoneChange = (e) => {
    setData({ ...data, mobile: e });
  };

  const handleImageChange = async (e) => {
    const selectedFile = e.target.files[0];

    const fileSize = selectedFile.size / 1024 / 1024; // Convert bytes to megabytes
    if (fileSize > 2) {
      alert(
        "File size is too large. Maximum size is 2MB. Please try any other image."
      );
      document.getElementById("image").value = "";
      return;
    }

    setData({ ...data, image: e.target.files[0] });
    setIsPreImg(URL.createObjectURL(selectedFile));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("mobile", data.mobile);
    formData.append("email", data.email);
    formData.append("address", data.address);
    formData.append("image", data.image);
    formData.append(
      "category",
      statusSelectedOption.label === "Other"
        ? isCatData.category
        : statusSelectedOption.label
    );

    axios
      .post(`${url}/api/vendor_ads/add_vendor_ads`, formData, {
        headers: {
          "x-access-token": access_token,
        },
      })
      .then((res) => {
        if (res.data.responseCode === 200) {
          addToast("Vendor ad added successfully...", {
            appearance: "success",
            autoDismiss: true,
          });
          isModalClose();
          setData({
            title: "",
            description: "",
            mobile: "",
            email: "",
            address: "",
            image: "",
          });
          setIsPreImg(null);
          setStatusSelectedOption();
        } else {
          addToast(res.data.responseMessage, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((err) => {
        addToast(err, {
          appearance: "error",
          autoDismiss: true,
        });
      });

    axios
      .post(`${url}/api/vendor_ads/add_ads_cat`, isCatData, {
        headers: {
          "x-access-token": access_token,
        },
      })
      .then((res) => {
        if (res.data.responseCode === 200) {
          setIsCatData({ ...isCatData, category: "" });
        } else {
          addToast(res.data.responseMessage, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((err) => {
        addToast(err, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  // console.log(statusSelectedOption);

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => onClose("addDialog")}
      backdrop="static"
    >
      <div className="modal-header">
        <h2 className="modal-title" id="modal-title-default">
          {heading}
        </h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onClose("addDialog")}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="12">
              <FormGroup>
                <ReactSelect
                  options={isCategoryList.map((item) => {
                    return {
                      value: item.vendor_ads_category_id,
                      label: item.category,
                    };
                  })}
                  onChange={setStatusSelectedOption}
                  placeholder="Select the categories"
                  styles={colorStyles}
                />
              </FormGroup>
            </Col>
            {statusSelectedOption?.label === "Other" && (
              <Col md="12">
                <FormGroup>
                  <Input
                    className="form-control-alternative"
                    placeholder="Enter Category Name"
                    type="text"
                    name="category"
                    onChange={(e) =>
                      setIsCatData({ ...isCatData, category: e.target.value })
                    }
                    value={isCatData.category}
                    required
                  />
                </FormGroup>
              </Col>
            )}
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Vendor Ad Title"
                  type="text"
                  name="title"
                  onChange={(e) => onDataChange(e)}
                  value={data.title}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="12">
              {/* <FormGroup> */}
              <textarea
                className="form-control-alternative textareaStyle rounded"
                placeholder="Enter Description"
                type="text"
                name="description"
                maxLength={characterLimit}
                onChange={(e) => onDataChange(e)}
                value={data.description}
                required
              />
              <Container className="d-flex justify-content-end">
                <p
                  style={{
                    marginBottom: "0",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  {data.description.length}/{characterLimit}
                </p>
              </Container>
              {/* </FormGroup> */}
            </Col>
            <Col md="12">
              <FormGroup>
                <PhoneInput
                  country={"in"}
                  onChange={handlePhoneChange}
                  value={data.mobile}
                  name="mobile"
                  inputStyle={{
                    position: "unset",
                    width: "100%",
                    boxShadow:
                      "0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%)",
                    border: "none",
                  }}
                  buttonStyle={{
                    border: "none",
                  }}
                  isValid={(value, country) => {
                    if (value === country.dialCode) {
                      return true;
                    }
                    return isValidPhoneNumber("+" + value);
                  }}
                  enableSearch
                  defaultErrorMessage="Please enter valid number"
                  required
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Vendor Email"
                  type="email"
                  name="email"
                  onChange={(e) => onDataChange(e)}
                  value={data.email}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  placeholder="Enter Vendor Address"
                  type="text"
                  name="address"
                  onChange={(e) => onDataChange(e)}
                  value={data.address}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input
                  style={{ padding: "5px 13px" }}
                  className="form-control-alternative rounded"
                  placeholder="Enter Address"
                  type="file"
                  name="image"
                  id="image"
                  accept="image/heic, image/jpeg, image/png, image/jpg"
                  onChange={handleImageChange}
                  required
                  //   value={data.image}
                />
                <Container className="d-flex justify-content-center">
                  <p
                    style={{
                      color: "#ff0000",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    .jpg, .png, .jpeg, .heic image formats are supported *
                  </p>
                </Container>
              </FormGroup>
            </Col>

            {isPreImg ? (
              <Col
                md="12"
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  src={isPreImg}
                  alt="Preview"
                  width="100"
                  height="75"
                  className="rounded"
                />
              </Col>
            ) : null}
          </Row>
          <div className="modal-footer">
            <Button
              className="ml-auto"
              color="primary"
              outline
              data-dismiss="modal"
              type="button"
              onClick={() => onClose("addDialog")}
            >
              Close
            </Button>
            <Button
              color="primary"
              type="submit"
              //   onClick={handleSubmit}
              disabled={
                !isValidPhoneNumber("+" + data.mobile) ||
                data.title.trim() === "" ||
                data.description.trim() === "" ||
                data.email.trim() === "" ||
                data.address.trim() === "" ||
                !statusSelectedOption
                  ? true
                  : false
              }
            >
              {loading ? `Adding..` : `Add`}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default AddVendorAds;
