/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { useToasts } from "react-toast-notifications";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { changePassword, decodeToken } from "services/authServices";

const Profile = () => {
  const { addToast } = useToasts();
  const [data, setData] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });
  const [showPassword, setShowPassword] = useState({
    oldpassword: false,
    newpassword: false,
    confirmpassword: false,
  });
  const [user, setUser] = useState(decodeToken());

  const handleShowPassword = (e,dialog) => {
    setShowPassword({ ...showPassword, [dialog]: ! showPassword[dialog] });
  };

  const handleDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      let res = await changePassword(data);
      if (res.status == 200) {
        addToast(res.data.message, {
          appearance: "success",
          autoDismiss: true,
        });
      }
      setData({
        oldpassword: "",
        newpassword: "",
        confirmpassword: "",
      });
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  return (
    <>
      <UserHeader
        username={user.username ? user.username : `Admin`}
        aboutMe=""
      />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="rounded-circle bg-gradient-orange"
                        src={require("../assets/img/theme/admin.png").default}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></CardHeader>
              <CardBody className="pt-0 pt-md-4 mt-md-3">
                <div className="text-center mt-md-5">
                  <h1>{user.username ? user.username : `Admin`}</h1>
                  <div className="h3 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    {user.email}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Reset Password</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleResetPassword}>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="old-password"
                          >
                            Old Password
                          </label>
                          <InputGroup className="input-group-alternative">
                          <Input
                            className="form-control-alternative"
                            // defaultValue="lucky.jesse"
                            id="old-password"
                            name="oldpassword"
                            value={data.oldpassword}
                            onChange={handleDataChange}
                            placeholder="Enter old password"
                            type={
                              !showPassword.oldpassword ? `password` : `text`
                            }
                          />
                           <InputGroupAddon addonType="append">
                              <InputGroupText>
                                {showPassword.oldpassword ? (
                                  <i
                                    className="fa fa-eye-slash"
                                    onClick={(e)=>handleShowPassword(e,"oldpassword")}
                                  />
                                ) : (
                                  <i
                                    className="fa fa-eye"
                                    onClick={(e)=>handleShowPassword(e,"oldpassword")}
                                  />
                                )}
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="new-password"
                          >
                            New Password
                          </label>
                          <InputGroup className="input-group-alternative">
                          <Input
                            className="form-control-alternative"
                            id="new-password"
                            name="newpassword"
                            value={data.newpassword}
                            onChange={handleDataChange}
                            placeholder="Enter new password"
                            type={
                              !showPassword.newpassword ? `password` : `text`
                            }
                          />
                           <InputGroupAddon addonType="append">
                              <InputGroupText>
                                {showPassword.newpassword ? (
                                  <i
                                    className="fa fa-eye-slash"
                                    onClick={(e)=>handleShowPassword(e,"newpassword")}
                                  />
                                ) : (
                                  <i
                                    className="fa fa-eye"
                                    onClick={(e)=>handleShowPassword(e,"newpassword")}
                                  />
                                )}
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="confirm-password"
                          >
                            Confirm Password
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Re enter password"
                              type={
                                !showPassword.confirmpassword
                                  ? `password`
                                  : `text`
                              }
                              name={`confirmpassword`}
                              value={data.confirmpassword}
                              onChange={handleDataChange}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                {showPassword.confirmpassword ? (
                                  <i
                                    className="fa fa-eye-slash"
                                    onClick={(e)=>handleShowPassword(e,"confirmpassword")}
                                  />
                                ) : (
                                  <i
                                    className="fa fa-eye"
                                    onClick={(e)=>handleShowPassword(e,"confirmpassword")}
                                  />
                                )}
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
