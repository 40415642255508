import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Button,
  Spinner,
  CardFooter,
  Pagination,
  PaginationItem,
  Form,
  CardHeader,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  PaginationLink,
} from "reactstrap";

const TablePagination = ({
  goToPreviousPage,
  goToNextPage,
  currentPage,
  pages,
  changePage,
  upper,
  lower,
}) => {

  let pageNumbers = [];
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }
  
  const renderPageNumbers = pageNumbers.map((number) => {
    if (number == 1 && currentPage == 1) {
      return (
        <PaginationItem
          className={number == currentPage ? `active` : ""}
          key={number}
        >
          <PaginationLink tag="button" onClick={changePage}>
            {number}
          </PaginationLink>
        </PaginationItem>
      );
    } else if (number < upper + 1 && number > lower) {
      return (
        <PaginationItem
          key={number}
          className={number == currentPage ? `active` : ""}
        >
          <PaginationLink tag="button" onClick={changePage}>
            {number}
          </PaginationLink>
        </PaginationItem>
      );
    }
  });

  return (
    <>
      <nav aria-label="...">
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <PaginationItem className={currentPage <= 1 ? `disabled` : ""}>
            <PaginationLink
              tag="button"
              onClick={goToPreviousPage}
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          <>{renderPageNumbers}</>

          <PaginationItem className={currentPage == pages ? `disabled` : ""}>
            <PaginationLink tag="button" onClick={goToNextPage}>
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </nav>
    </>
  );
};
export default TablePagination;
