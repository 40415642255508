import React from "react";

const Toast = ({ appearance, children }) => (
  <div
    style={{
      minWidth: "350px",
      padding: 12,
      color: "white",
      background: appearance == "success" ? "#2ecc71" : "#f5365c",
      borderRadius: 5,
      boxShadow: "0px 0px 2px rgba(255,255,255,0.5)",
      textAlign: "center",
      marginBottom: 5,
    }}
  >
    <p style={{ margin: 0, padding: 0, fontWeight: 900 }}>{children}</p>
  </div>
);

export default Toast;
