import React from "react";
import { Button, Col, Modal, Row } from "reactstrap";

function DeleteVendorAd({ heading, isOpen, onClose, loading, handleDelete }) {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => onClose("deleteDialog")}
      backdrop="static"
    >
      <div className="modal-header">
        <h2 className="modal-title" id="modal-title-default">
          {heading}
        </h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onClose("deleteDialog")}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Row>
        <Col md="12">
          <h3 style={{ padding: "0 25px" }}>
            Do you want to delete this item?
          </h3>
        </Col>
      </Row>
      <div className="modal-body">
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="primary"
            outline
            data-dismiss="modal"
            type="button"
            onClick={() => onClose("deleteDialog")}
          >
            Close
          </Button>
          <Button color="primary" type="button" onClick={handleDelete}>
            {loading ? `Deleting..` : `Delete`}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteVendorAd;
