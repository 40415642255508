import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import Details from "./Details";

const CareerCard = ({c}) => {
  return (
    <Row className="mt-4 mt-sm-0">
      <Col xl="12">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col>
                <h6 className="heading-small">Career Details</h6>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Details label={`Education`} description={c.education ? c.education : `-`} />
              <Details label={`Occupation`} description={c.occupation? c.occupation :'-'} />
              <Details label={`Employed in`} description={c.employeed_in ? c.employeed_in: '-'} />
              <Details label={`Annual Income`} description={c.annual_income ? c.annual_income + c.currency : '-'} />
             
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col xl="12" className="mt-4">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white">
            <Row className="align-items-center">
              <Col>
                <h6 className="heading-small">Lifestyle Details</h6>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Details label={`Eating habits`} description={c.eating ? c.eating : '-'} />
              <Details label={`Smoking habits`} description={c.smoking ? c.smoking : '-'} />
              <Details label={`Drinking habits`} description={c.drinking ? c.drinking : '-'} />
            </Row>
          </CardBody>
        </Card>
      </Col>

      <Col xl="12" className="mt-4">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white">
            <Row className="align-items-center">
              <Col>
                <h6 className="heading-small">Horoscope Details</h6>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Details label={`Star`} description={c.star ? c.star : '-'} />
              <Details label={`Rashi`} description={c.rashi ? c.rashi : '-'} />
              <Details label={`Time of birth`} description={c.time_of_birth ? c.time_of_birth : '-'} />
              <Details label={`Place of birth`} description={c.place_of_birth ? c.place_of_birth : '-'} />
              <Details label={`Gothra`} description={c.gotra ? c.gotra : '-'} />
              <Details label={`Should horoscope match`} description={c.horoscope_match ? c.horoscope_match : '-'} />
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>

    // <Card className="bg-secondary shadow">
    //   <CardHeader className="bg-white border-0">
    //     <Row className="align-items-center">
    //       <Col>
    //         <h6 className="heading-small">Career Details</h6>
    //       </Col>
    //     </Row>
    //   </CardHeader>
    //   <CardBody>
    //     <Row>
    //       <Details label={`Username`} description={`Jessica Jone`} />
    //       <Details label={`Gender`} description={`Female`} />
    //       <Details label={`Date of birth`} description={`23-07-1993`} />
    //       <Details label={`Height`} description={`5.7'`} />
    //       <Details label={`Weight`} description={`54kg`} />
    //       <Details label={`Physical Status`} description={`aaaaaa`} />
    //       <Details label={`Country`} description={`Rome`} />
    //       <Details label={`State`} description={`Roamania`} />
    //       <Details label={`City`} description={`Bucharest`} />
    //       <Details label={`Mother toungue`} description={`Romish`} />
    //       <Details label={`Marital status`} description={`Unmarried`} />
    //       <Details label={`Caste`} description={`Vishwakarma`} />
    //       <Details label={`Manglik`} description={`abcd`} />
    //     </Row>
    //   </CardBody>
    // </Card>
  );
};
export default CareerCard;
