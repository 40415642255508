import { http } from "./httpService";

const getInitialUserRequests = () => {
  return http.get(`/admins/listuser?status=pending&page=1`);
};

const getPageByPageUserRequests = (page) => {
  return http.get(`/admins/listuser?status=pending&page=${page}`);
};

const acceptUserRequest = (id) => {
  return http.put(`/admins/accept?user_id=${id}`);
};

const rejectUserRequest = (id) => {
  return http.put(`/admins/reject?user_id=${id}`);
};

const getInitialAcceptedUsers = (Approvedfilter, BlockedFilter) => {
  // alert(filter)
  return http.get(
    `/admins/listuser?status=${Approvedfilter}&page=1&status=${BlockedFilter}`
  );
  // return http.get(`/admins/listuser?status=${filter}&page=1`);
};
const getPageByPageAcceptedUsers = (Approvedfilter, BlockedFilter, page) => {
  return http.get(
    `/admins/listuser?status=${Approvedfilter}&page=${page}&status=${BlockedFilter}`
  );
};

const blockUser = (id) => {
  // return http.put(`/users/block?user_id=${id}`);
  return http.put(`/admins/block?user_id=${id}`);
};

const getInitialDeclinedUsers = () => {
  return http.get(`/users/initial/reject`);
};

const getPageByPageDeclinedUsers = (page) => {
  return http.get(`/users/reject/${page}`);
};

const getUserDetails = (id) => {
  return http.get(`/retrieve/userDetails?user_id=${id}`);
};

const getPendingSearchFilter = (username) => {
  return http.get(`/users/search/pend/${username}`);
};
const getAcceptedSearchFilter = (username) => {
  return http.get(`/users/search/accept/${username}`);
  // return http.get(`admins/searchuser?status=approved&username=${username}`);
};

const getAdminsSearchUser = (username, status) => {
  return http.get(`/admins/searchuser?status=${status}&username=${username}`);
};

const getRejectedSearchFilter = (username) => {
  return http.get(`/users/search/reject/${username}`);
};
const getAnalyticsDetails = () => {
  return http.get(`/analysis/details`);
};
const getUserAnalytics = () => {
  return http.get(`/analysis/user`);
};
const getUserDeleteRequestList = () => {
  return http.get(`/api/user/list_delete_request`);
};
const getAcceptUserDeleteRequest = (id) => {
  return http.put(`/api/user/accept_delete_request/${id}`);
};
const getReportedUserRequest = () => {
  return http.get(`/api/user_report/list_reported_user`);
};
const getBlockedUserList = () => {
  return http.get(`/api/user_report/admin/list_blocked_user`);
};
const getWhoAcceptedWhomList = () => {
  return http.get(`/api/admin/accepted/requests`);
};
const getDeletedUsersList = () => {
  return http.get(`/api/user/deleted/list`);
};

export {
  getInitialUserRequests,
  getPageByPageUserRequests,
  acceptUserRequest,
  rejectUserRequest,
  getInitialAcceptedUsers,
  getPageByPageAcceptedUsers,
  blockUser,
  getInitialDeclinedUsers,
  getPageByPageDeclinedUsers,
  getUserDetails,
  getPendingSearchFilter,
  getAcceptedSearchFilter,
  getRejectedSearchFilter,
  getUserAnalytics,
  getAnalyticsDetails,
  getUserDeleteRequestList,
  getAcceptUserDeleteRequest,
  getReportedUserRequest,
  getBlockedUserList,
  getAdminsSearchUser,
  getWhoAcceptedWhomList,
  getDeletedUsersList,
};
