import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Card, CardHeader, Container, Row, Table } from "reactstrap";
import { getDeletedUsersList } from "services/userService";

function Index() {
  const [users, setUsers] = useState([]);
  const history = useHistory();
  const { addToast } = useToasts();

  useEffect(() => {
    const loginTime = localStorage.getItem("expirationTime");
    const elapsedTime = Date.now() - loginTime;

    if (elapsedTime > 24 * 60 * 60 * 1000) {
      // Check if 24 hours have elapsed
      localStorage.removeItem("token");
      localStorage.removeItem("expirationTime");
      history.push("/login");
    }

    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      const res = await getDeletedUsersList();
      if (res.data.responseCode === 200) {
        setUsers(res.data.responseData);
      } else {
        addToast(res.data.responseMessage, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      addToast(error.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <div>
      <Header />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex align-items-center justify-content-between flex-md-row flex-column">
                <h3 className="mb-0">View All Deleted Users</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">View Details</th>
                    {/* <th scope="col">Respond</th> */}
                  </tr>
                </thead>
                <tbody>
                  {users.length > 0 ? (
                    users.map((user) => (
                      <tr key={user.user_id}>
                        <td
                          className="d-flex align-items-center"
                          style={{ gap: "5px" }}
                        >
                          <img
                            src={user.profile_link}
                            alt="ahm"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "contain",
                            }}
                            className="rounded-circle"
                          />
                          <span className="d-flex flex-column">
                            <span>{user.username}</span>
                          </span>
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>
                          <Link to={`/admin/view-user/${user.user_id}`}>
                            View Details
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <th colSpan="5">
                        {/* {loading ? "Loading..." : "No Data Found!!"} */}
                        No Data Found!!
                      </th>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default Index;
