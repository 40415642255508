import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Table,
  Button,
  Media,
  CardFooter,
  Spinner,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import Switch from "@material-ui/core/Switch";

import AddVersion from "./components/AddVersion";
import UpdateVersion from "./components/UpdateVersion";
import TablePagination from "./components/TablePagination";
import {
  addVersion,
  getVersionList,
  updateVersion,
  updateMaintenance,
} from "services/versionControlService";
import { useHistory } from "react-router-dom";

const DailyPanchanga = () => {
  const { addToast } = useToasts();
  const [list, setList] = useState([]);
  const [state, setState] = useState({});
  const [maintenance, setMaintenance] = useState({});
  const [panchangaList, setPanchangaList] = useState([]);
  const [data, setData] = useState({
    android_version: "",
    ios_version: "",
  });
  const [dialogs, setDialogs] = useState({
    addDialog: false,
    deleteDialog: false,
    updateDialog: false,
  });
  const [respondData, setRespondData] = useState({});
  const [pagination, setPagination] = useState({
    upperPageBound: 3,
    lowerPageBound: 0,
    pageBound: 3,
    count: 0,
  });
  const [loading, setLoading] = useState({
    list: false,
    add: false,
    update: false,
    delete: false,
  });
  const dataLimit = 10;
  const [type, setType] = useState("");
  const [pages, setPages] = useState(Math.round(list.length / dataLimit));
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  useEffect(() => {
    const loginTime = localStorage.getItem("expirationTime");
    const elapsedTime = Date.now() - loginTime;

    if (elapsedTime > 24 * 60 * 60 * 1000) {
      // Check if 24 hours have elapsed
      localStorage.removeItem("token");
      localStorage.removeItem("expirationTime");
      history.push("/login");
    }

    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading({ ...loading, list: true });
    try {
      let res = await getVersionList();
      if (res.data.responseCode == 200) {
        setLoading({ ...loading, list: false });
        setPages(
          Math.ceil(res.data.responseData.version_list.length / dataLimit)
        );
        let a = state;
        res.data.responseData.version_list.map(({ version_id, status }) => {
          return status === 1
            ? (a["check" + version_id] = true)
            : (a["check" + version_id] = false);
        });

        setState(a);
        setMaintenance(res.data.responseData.maintenance_mode);
        setPanchangaList(res.data.responseData.version_list);
        setList(res.data.responseData.version_list);
      } else {
        addToast(res.data.responseMessage, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  function goToNextPage() {
    if (currentPage + 1 > pagination.upperPageBound) {
      pagination.upperPageBound =
        pagination.upperPageBound + pagination.pageBound;
      pagination.lowerPageBound =
        pagination.lowerPageBound + pagination.pageBound;
    }
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    if ((currentPage - 1) % pagination.pageBound === 0) {
      pagination.upperPageBound =
        pagination.upperPageBound - pagination.pageBound;
      pagination.lowerPageBound =
        pagination.lowerPageBound - pagination.pageBound;
    }
    setCurrentPage((page) => page - 1);
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return panchangaList.slice(startIndex, endIndex);
  };

  const handleOpenDialog = (e, dialog, data) => {
    setType(dialog);
    // if (data) {
    //   setRespondData(data);
    // }
    setDialogs({ ...dialogs, [dialog]: true });
  };

  const handleDataChange = (e) => {
    let d = { ...data };
    d[e.target.name] = e.target.value;
    setData(d);
  };

  const handleRespondDataChange = (e) => {
    let d = { ...respondData };
    d[e.target.name] = e.target.value;
    setRespondData(d);
  };

  const handleAdd = async () => {
    try {
      data.access_token = localStorage.getItem("token");
      if (data.android_version == "" || data.ios_version == "") {
        addToast("Fields can't be empty!!", {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        setLoading({ ...loading, add: true });

        let res = await addVersion(data);
        setLoading({ ...loading, add: false });
        if (res.status == 201) {
          setData({
            android_version: "",
            ios_version: "",
          });
          setDialogs({ ...dialogs, addDialog: false });
          addToast("Version added", {
            appearance: "success",
            autoDismiss: true,
          });
          fetchData();
        } else {
          addToast(res.data.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    } catch (err) {
      addToast(err.response.data, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleUpdate = async () => {
    respondData.access_token = localStorage.getItem("token");
    let updatedData = {
      access_token: localStorage.getItem("token"),
      version_id: respondData.version_id,
      android_version: respondData.android_version,
      ios_version: respondData.ios_version,
    };
    setLoading({ ...loading, update: true });
    console.log("respondData = ", updatedData);
    try {
      let res = await updateVersion(updatedData);
      setLoading({ ...loading, update: false });
      if (res.data.responseCode == 200) {
        setRespondData({});
        setDialogs({ ...dialogs, [type]: false });
        addToast("Version updated", {
          appearance: "success",
          autoDismiss: true,
        });
        fetchData();
      } else {
        addToast(res.data.responseMessage, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (err) {
      addToast(err.response.data, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleClose = (dialog) => {
    setDialogs({ ...dialogs, [dialog]: false });
  };

  //function to change the state of the switch button
  const handleChange = async (event) => {
    //if switch is checked then it will be unchecked and vice versa
    let statusFlag = event.target.checked === true ? 1 : 0;
    let targetChecked = event.target.checked;
    let targetName = event.target.name;

    const res = await updateVersion({
      access_token: localStorage.getItem("token"),
      version_id: event.target.id,
      status: statusFlag,
    });
    if (res.data.responseCode === 200) {
      setState({ ...state, [targetName]: targetChecked });
    } else {
      addToast(res.data.responseMessage, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleMaintenanceChange = async (event) => {
    //if switch is checked then it will be unchecked and vice versa
    let maintenanceFlag = event.target.checked === true ? 1 : 0;
    console.log("aaaaaaaaa = ", {
      access_token: localStorage.getItem("token"),
      maintenance_mode: maintenanceFlag,
    });
    const res = await updateMaintenance({
      access_token: localStorage.getItem("token"),
      maintenance_mode: maintenanceFlag,
    });
    if (res.data.responseCode === 200) {
      setMaintenance(maintenanceFlag);
    } else {
      addToast(res.data.responseMessage, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-transparent border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Version Control</h3>
                  </div>
                  <div className="col text-right">
                    <Row
                      className="align-items-center"
                      style={{ "justify-content": "right" }}
                    >
                      <h3 className="mb-0">Maintainace Mode</h3>
                      <Switch
                        color="primary"
                        checked={maintenance}
                        id={maintenance}
                        onClick={handleMaintenanceChange}
                        name="maintenance"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Row>
                  </div>
                  <div className="col text-right">
                    <div
                      className="icon icon-shape bg-primary text-white rounded-circle shadow"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleOpenDialog(e, "addDialog")}
                    >
                      <i className="fas fa-plus" style={{ fontSize: 10 }}></i>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead style={{ backgroundColor: "#f6f9fc", color: "#8898aa" }}>
                  <tr>
                    <th className="text-center" scope="col">
                      Version Id
                    </th>
                    <th className="text-center" scope="col">
                      Android Version
                    </th>
                    <th className="text-center" scope="col">
                      IOS Version
                    </th>
                    <th className="text-center" scope="col">
                      Status
                    </th>
                    <th
                      scope="col"
                      style={{ textAlign: "center", width: "150px" }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {loading.list ? (
                    <tr>
                      <td
                        colSpan={6}
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        <Spinner type="grow" color="primary" />
                      </td>
                    </tr>
                  ) : panchangaList.length > 0 ? (
                    getPaginatedData().map((e) => (
                      <tr key={e.version_id}>
                        <td className="text-center">{e.version_id}</td>
                        <td className="text-center">{e.android_version}</td>
                        <td className="text-center">{e.ios_version}</td>
                        <td className="text-center">
                          <Switch
                            color="primary"
                            checked={state["check" + e.version_id]}
                            id={e.version_id}
                            onClick={handleChange}
                            name={"check" + e.version_id}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </td>
                        <td className="text-center">
                          <i
                            className="fa fa-edit text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={(event) =>
                              handleOpenDialog(event, "updateDialog", e)
                            }
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <th colSpan="5">No Data Found!!</th>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <TablePagination
                  goToPreviousPage={goToPreviousPage}
                  goToNextPage={goToNextPage}
                  currentPage={currentPage}
                  pages={pages}
                  changePage={changePage}
                  upper={pagination.upperPageBound}
                  lower={pagination.lowerPageBound}
                />
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>

      <AddVersion
        heading={"Add New Application Version"}
        isOpen={dialogs.addDialog}
        data={data}
        onClose={handleClose}
        handleAdd={handleAdd}
        onDataChange={handleDataChange}
        loading={loading.add}
      />

      <UpdateVersion
        heading={"Update Version"}
        isOpen={dialogs.updateDialog}
        data={respondData}
        onClose={handleClose}
        handleUpdate={handleUpdate}
        onDataChange={handleRespondDataChange}
        loading={loading.update}
      />
    </>
  );
};

export default DailyPanchanga;
