import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { login, decodeToken } from "services/authServices";

const Login = ({ history }) => {
  const { addToast } = useToasts();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      let res = await login(data);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("expirationTime", Date.now());
      history.push("/admin/dashboard");
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-3">
            <h1 className="text-center mt-2">Sign In</h1>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={handleLogin}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter email"
                    type="text"
                    name="email"
                    value={data.email}
                    onChange={handleDataChange}
                    // autoComplete="new-email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>

                  <Input
                    placeholder="Enter password"
                    type={!showPassword ? `password` : `text`}
                    name={`password`}
                    value={data.password}
                    onChange={handleDataChange}
                    autoComplete="new-password"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      {showPassword ? (
                        <i
                          className="fa fa-eye-slash"
                          onClick={handleShowPassword}
                        />
                      ) : (
                        <i className="fa fa-eye" onClick={handleShowPassword} />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col
            xs="12"
            className="d-flex align-items-center justify-content-center"
          >
            <a className="text-light" href="/auth/forgot-password">
              <small>Forgot password?</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default withRouter(Login);
