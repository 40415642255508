import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { resetPassword } from "services/authServices";

const ForgotPasswordPage = (props) => {
  const { addToast } = useToasts();

  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    let r = {};
    r.newpassword = data.password;
    r.confirmpassword = data.confirmPassword;
    try {
      localStorage.setItem("code", props.match.params.code);
      let res = await resetPassword(r);
      if (res.status == 200) {
        addToast(res.data.message, {
          appearance: "success",
          autoDismiss: true,
        });
        setData({ ...data, password: "", confirmPassword: "" });
        props.history.push("/auth/login");
      }
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    localStorage.removeItem("code");
  };
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <h1 className="text-center mt-2">Reset Password</h1>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>

                  <Input
                    placeholder="Enter password"
                    type={!showPassword ? `password` : `text`}
                    name={`password`}
                    value={data.password}
                    onChange={handleDataChange}
                    autoComplete="new-password"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      {showPassword ? (
                        <i
                          className="fa fa-eye-slash"
                          onClick={handleShowPassword}
                        />
                      ) : (
                        <i className="fa fa-eye" onClick={handleShowPassword} />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>

                  <Input
                    placeholder="Re enter password"
                    type={!showConfirmPassword ? `password` : `text`}
                    name={`confirmPassword`}
                    value={data.confirmPassword}
                    onChange={handleDataChange}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      {showConfirmPassword ? (
                        <i
                          className="fa fa-eye-slash"
                          onClick={handleShowConfirmPassword}
                        />
                      ) : (
                        <i
                          className="fa fa-eye"
                          onClick={handleShowConfirmPassword}
                        />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  onClick={handleResetPassword}
                >
                  Update
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col
            xs="12"
            className="d-flex align-items-center justify-content-center"
          >
            <a className="text-light" href="/auth/login">
              <small>Login?</small>
            </a>
          </Col>
        </Row> */}
      </Col>
    </>
  );
};

export default withRouter(ForgotPasswordPage);
