/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";

// reactstrap components
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import ModalDialog from "./ModalDialog";
import EditModal from "./EditModal";
import {
  getInitialAcceptedUsers,
  getPageByPageAcceptedUsers,
  acceptUserRequest,
  blockUser,
  getAcceptedSearchFilter,
} from "services/userService";
import TablePagination from "components/TablePagination";
import { Link, useHistory } from "react-router-dom";
import { getAdminsSearchUser } from "services/userService";

const Users = () => {
  const { addToast } = useToasts();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [respondData, setRespondData] = useState({});
  const [modal, setModal] = useState({
    blockModal: false,
    unblockModal: false,
    editModal: false,
  });
  const [filterType, setFilterType] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    upperPageBound: 3,
    lowerPageBound: 0,
    pageBound: 3,
    rowsPerPage: 10,
    count: 0,
  });
  const history = useHistory();
  const [reducedValue, forceUpdate] = React.useReducer((x) => x + 1, 0);

  useEffect(() => {
    const loginTime = localStorage.getItem("expirationTime");
    const elapsedTime = Date.now() - loginTime;

    if (elapsedTime > 24 * 60 * 60 * 1000) {
      // Check if 24 hours have elapsed
      localStorage.removeItem("token");
      localStorage.removeItem("expirationTime");
      history.push("/login");
    }

    fetchInitialData();
  }, [reducedValue]);

  let stateNames = {
    0: "Accepted Users",
    1: "Rejected Users",
  };

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      let res = await getInitialAcceptedUsers("approved", "blocked");
      pagination.count = res.data.size;
      setUsers(res.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleChangePage = async (e, newPage) => {
    pagination.page = newPage;
    await fetchPageByPageUsers(newPage);
  };
  const fetchPageByPageUsers = async (page) => {
    try {
      let res = await getPageByPageAcceptedUsers(
        !filterType ? "approved" : "rejected",
        "blocked",
        page
      );
      setUsers(res.data.data);
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const toggleModal = (e, dialog, data) => {
    setRespondData(data);
    setModal({ ...modal, [dialog]: !modal[dialog] });
  };

  const closeModal = (e, dialog) => {
    setModal({ ...modal, [dialog]: !modal[dialog] });
  };

  const handleBlockUser = async (e) => {
    e.preventDefault();
    try {
      let res = await blockUser(respondData.user_id);
      if (res.status == 200) {
        addToast("Blocked successfully!!", {
          appearance: "success",
          autoDismiss: true,
        });
        setModal({ ...modal, blockModal: false });
        fetchPageByPageUsers(pagination.page);
        forceUpdate();
      }
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleUnblockUser = async (e) => {
    e.preventDefault();
    try {
      let res = await acceptUserRequest(respondData.user_id);
      if (res.status == 200) {
        addToast("Unblocked successfully!!", {
          appearance: "success",
          autoDismiss: true,
        });
        setModal({ ...modal, unblockModal: false });
        fetchPageByPageUsers(pagination.page);
        forceUpdate();
      }
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const btnPrevClick = (e) => {
    if ((pagination.page - 1) % pagination.pageBound === 0) {
      pagination.upperPageBound =
        pagination.upperPageBound - pagination.pageBound;
      pagination.lowerPageBound =
        pagination.lowerPageBound - pagination.pageBound;
    }
    handleChangePage(e, pagination.page - 1);
  };
  const btnNextClick = (e) => {
    if (pagination.page + 1 > pagination.upperPageBound) {
      pagination.upperPageBound =
        pagination.upperPageBound + pagination.pageBound;
      pagination.lowerPageBound =
        pagination.lowerPageBound + pagination.pageBound;
    }
    handleChangePage(e, pagination.page + 1);
  };

  const handleSearch = async (e) => {
    setSearch(e.target.value);
    try {
      if (e.target.value.length == 0 || e.target.value == "") {
        fetchPageByPageUsers(pagination.page);
      } else {
        // let res = await getAcceptedSearchFilter(e.target.value);
        let res = await getAdminsSearchUser(
          e.target.value,
          !filterType ? "approved" : "rejected"
        );
        setUsers(res.data.data);
      }
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleFilter = async (filter) => {
    try {
      setLoading(true);
      setUsers([]);
      let res = await getInitialAcceptedUsers(
        !filter ? "approved" : "rejected",
        !filter ? "blocked" : "rejected"
      );
      pagination.count = res.data.size;
      setFilterType(filter ? filter : 0);
      setUsers(res.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      // addToast(err.response.data, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex align-items-center justify-content-between flex-md-row flex-column">
                <h3 className="mb-0">Users List</h3>
                <div className="d-flex align-items-center">
                  <Form>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-control-alternative"
                        placeholder="Search by name"
                        type="text"
                        value={search}
                        onChange={handleSearch}
                      />
                    </InputGroup>
                  </Form>
                  <div className="col text-right">
                    <div>
                      <UncontrolledDropdown nav>
                        <DropdownToggle nav>
                          <Button
                            className="ml-auto"
                            color="primary"
                            outline
                            type="button"
                            // style={{"color": "white"}}
                            size="sm"
                          >
                            {stateNames[filterType]}
                          </Button>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              handleFilter(0);
                            }}
                          >
                            <h5 className="text-overflow m-0">
                              Accepted Users
                            </h5>
                          </DropdownItem>

                          <DropdownItem divider />

                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              handleFilter(1);
                            }}
                          >
                            <h5 className="text-overflow m-0">
                              Rejected Users
                            </h5>
                          </DropdownItem>

                          {/* <DropdownItem divider />

                        <DropdownItem
                          // onClick={(e) => {
                          //   e.preventDefault();
                          //   handleFilter(1);
                          // }}
                        >
                          <h5 className="text-overflow m-0">Blocked Users</h5>
                        </DropdownItem> */}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">View Details</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users && users.length >= 1 ? (
                    users.length > 0 ? (
                      users.map((user) => (
                        <tr key={user.user_id}>
                          <td
                            className="d-flex align-items-center"
                            style={{ gap: "5px" }}
                          >
                            <img
                              src={user.profile_link}
                              alt="ahm"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "contain",
                              }}
                              className="rounded-circle"
                            />
                            <span className="d-flex flex-column">
                              <span>{user.username}</span>
                            </span>
                          </td>
                          <td>{user.email}</td>
                          <td>{user.phone}</td>
                          <td>
                            <Link to={`/admin/view-user/${user.user_id}`}>
                              View Details
                            </Link>
                          </td>
                          <td>
                            <Button
                              // color="primary"
                              style={{ width: "100px" }}
                              color={
                                user.status === "approved"
                                  ? "danger"
                                  : "success"
                              }
                              type="button"
                              onClick={(e) =>
                                toggleModal(
                                  e,
                                  user.status == "approved"
                                    ? `blockModal`
                                    : `unblockModal`,
                                  user
                                )
                              }
                            >
                              {user.status == `approved` ? `Block` : `Unblock`}
                            </Button>
                            {/* <Button
                              color="primary"
                              type="button"
                              onClick={(e) => toggleModal(e, "editModal")}
                            >
                              Edit
                            </Button> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <th colSpan="5">
                          {loading ? "Loading..." : "No Data Found!!"}
                        </th>
                      </tr>
                    )
                  ) : (
                    <tr className="text-center">
                      <th colSpan="5">
                        {loading ? "Loading..." : "No Data Found!!"}
                      </th>
                    </tr>
                  )}
                </tbody>
              </Table>
              {search.length < 1 && (
                <CardFooter className="py-4">
                  <TablePagination
                    upper={pagination.upperPageBound}
                    lower={pagination.lowerPageBound}
                    page={pagination.page}
                    btnNextClick={btnNextClick}
                    btnPrevClick={btnPrevClick}
                    count={pagination.count}
                    rowsPerPage={pagination.rowsPerPage}
                    handleChangePage={handleChangePage}
                  />
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>

        <ModalDialog
          isOpen={modal.blockModal}
          toggleModal={(e) => closeModal(e, "blockModal")}
          type="block"
          data={respondData}
          handleBlockUser={handleBlockUser}
        />
        <ModalDialog
          isOpen={modal.unblockModal}
          toggleModal={(e) => closeModal(e, "unblockModal")}
          type="unblock"
          data={respondData}
          handleUnblockUser={handleUnblockUser}
        />
        {/* <EditModal
          isOpen={modal.editModal}
          toggleModal={(e) => closeModal(e, "editModal")}
          type="editModal"
          data={respondData}
        /> */}
      </Container>
    </>
  );
};

export default Users;
