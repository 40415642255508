import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import Details from "./Details";

const FamilyBase = ({data, countryList}) => {
  return (
    <Row className="mt-4">
      <Col xl="12">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col>
                <h6 className="heading-small">Family Base in</h6>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Details label={`Country Living in`} description={data.family_country ? countryList[data.family_country] :`-`} />
              <Details label={`State`} description={data.family_state ? data.family_state :`-`} />
              <Details label={`City`} description={data.family_city ? data.family_city :`-`} />
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
export default FamilyBase;
