import { http } from "./httpService";
import decode from "jwt-decode";
import { data } from "jquery";

const getToken = () => {
  return localStorage.getItem("token");
};

const decodeToken = () => {
  let token = localStorage.getItem("token");
  return decode(token);
};

const login = (data) => {
  let loginData = {};
  loginData.email = data.email;
  loginData.password = data.password;
  return http.post(`/admins/login`, loginData);
};

const refreshToken = () => {
  return http.put(`/admins/refresh-token`);
};

const changePassword = (data) => {
  return http.put(`/admins/changepassword`, data);
};

const sendResetPasswordEmail = (data) => {
  let f = {};
  f.email = data;
  return http.post(`/admins/forgot`, f);
};
const resetPassword =(data)=>{
  return http.post(`/admins/reset`, data);
}
export {
  getToken,
  login,
  decodeToken,
  refreshToken,
  changePassword,
  sendResetPasswordEmail,
  resetPassword
};
