import axios from "axios";
import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { url } from "../../config.json";
import ModalImage from "react-modal-image";
import { useHistory } from "react-router-dom";

const Paginationn = ({ items, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(items.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, items.length);
  const [loading, setLoading] = useState({
    list: false,
    add: false,
    update: false,
    delete: false,
  });
  const access_token = localStorage.getItem("token");
  const history = useHistory();

  useEffect(() => {
    const loginTime = localStorage.getItem("expirationTime");
    const elapsedTime = Date.now() - loginTime;

    if (elapsedTime > 24 * 60 * 60 * 1000) {
      // Check if 24 hours have elapsed
      localStorage.removeItem("token");
      localStorage.removeItem("expirationTime");
      history.push("/login");
    }
  }, []);

  const handleNextPage = () => {
    if (items.length === 0) {
      setCurrentPage(currentPage);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <div>
      <Table className="align-items-center table-flush" responsive>
        <thead style={{ backgroundColor: "#f6f9fc", color: "#8898aa" }}>
          <tr>
            <th className="text-left" scope="col">
              Title
            </th>
            <th className="text-left" scope="col">
              Description
            </th>
            <th className="text-left" scope="col">
              Mobile
            </th>
            <th className="text-left" scope="col">
              Email
            </th>
            <th className="text-left" scope="col">
              Address
            </th>
            <th className="text-center" scope="col" style={{ width: "25%" }}>
              Image
            </th>
            <th className="text-center" scope="col">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {loading.list ? (
            <tr>
              <td colSpan={6} style={{ width: "100%", textAlign: "center" }}>
                <Spinner type="grow" color="primary" />
              </td>
            </tr>
          ) : currentItems.length > 0 ? (
            // getPaginatedData().map((e) => (
            currentItems.map((e, i) => (
              <tr key={e.vendor_ads_id}>
                <td className="text-left">{e.title}</td>
                <td className="text-left">{e.description}</td>
                <td className="text-left">+{e.mobile}</td>
                <td className="text-left">{e.email}</td>
                <td className="text-left">{e.address}</td>
                <td className="text-center">
                  <ModalImage
                    small={url + e.image}
                    large={url + e.image}
                    alt={e.title}
                    className="imgWidth rounded border"
                  />
                </td>
                <td className="text-center">
                  <h6
                    style={{
                      fontSize: "14px",
                      color: "#fff",
                      padding: "5px 10px",
                      backgroundColor: "#ff0000",
                      borderRadius: "50px",
                    }}
                  >
                    Expired
                  </h6>
                </td>
              </tr>
            ))
          ) : (
            <tr className="text-center">
              <th colSpan="7">No Data Found!!</th>
            </tr>
          )}
        </tbody>
      </Table>
      <Container className="justify-content-end align-items-center d-flex mr-1 mt-2 mb-2">
        <Container className="d-flex align-items-center justify-content-end">
          <p style={{ marginBottom: "0", fontWeight: "500" }}>
            {startIndex}-{endIndex} /{items.length}
          </p>
        </Container>
        <Button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          style={{ borderRadius: "50px" }}
        >
          <i className="fas fa-angle-left" />
        </Button>
        <Button
          onClick={handleNextPage}
          style={{ borderRadius: "50px" }}
          disabled={currentPage === totalPages}
        >
          <i className="fas fa-angle-right" />
        </Button>
      </Container>
    </div>
  );
};

function Index() {
  const [expiredVendorAdsList, setExpiredVendorAdsList] = useState([]);
  const [loading, setLoading] = useState({
    list: false,
    add: false,
    update: false,
    delete: false,
  });
  const { addToast } = useToasts();
  const access_token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(`${url}/api/vendor_ads/expired_vendor_ads`, {
        headers: { "x-access-token": access_token },
      })
      .then((res) => {
        if (res.data.responseCode === 200) {
          setExpiredVendorAdsList(res.data.responseData);
          setLoading({ ...loading, list: false });
        } else {
          addToast(res.data.responseMessage, {
            appearance: "error",
            autoDismiss: true,
          }).catch((err) => {
            addToast(err.response.data.message, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        }
      });
  }, [expiredVendorAdsList]);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-transparent border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Expired Vendor Ads</h3>
                  </div>
                  {/* <div className="col text-right">
                    <div
                      className="icon icon-shape bg-primary text-white rounded-circle shadow"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleOpenDialog(e, "addDialog")}
                    >
                      <i className="fas fa-plus" style={{ fontSize: 10 }}></i>
                    </div>
                  </div> */}
                </Row>
              </CardHeader>
              <Paginationn items={expiredVendorAdsList} itemsPerPage={5} />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Index;
