import Header from "components/Headers/Header";
import TablePagination from "components/TablePagination";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
} from "reactstrap";
import { getBlockedUserList } from "services/userService";

function Index() {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    upperPageBound: 3,
    lowerPageBound: 0,
    pageBound: 3,
    rowsPerPage: 10,
    count: 0,
  });
  const history = useHistory();
  const { addToast } = useToasts();

  useEffect(() => {
    const loginTime = localStorage.getItem("expirationTime");
    const elapsedTime = Date.now() - loginTime;

    if (elapsedTime > 24 * 60 * 60 * 1000) {
      // Check if 24 hours have elapsed
      localStorage.removeItem("token");
      localStorage.removeItem("expirationTime");
      history.push("/login");
    }

    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      let res = await getBlockedUserList();
      pagination.count = res.data.size;
      setUsers(res.data.responseData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const btnPrevClick = (e) => {
    if ((pagination.page - 1) % pagination.pageBound === 0) {
      pagination.upperPageBound =
        pagination.upperPageBound - pagination.pageBound;
      pagination.lowerPageBound =
        pagination.lowerPageBound - pagination.pageBound;
    }
    handleChangePage(e, pagination.page - 1);
  };
  const btnNextClick = (e) => {
    if (pagination.page + 1 > pagination.upperPageBound) {
      pagination.upperPageBound =
        pagination.upperPageBound + pagination.pageBound;
      pagination.lowerPageBound =
        pagination.lowerPageBound + pagination.pageBound;
    }
    handleChangePage(e, pagination.page + 1);
  };

  const handleChangePage = async (e, newPage) => {
    pagination.page = newPage;
    // await fetchPageByPageRequests(newPage);
  };

  return (
    <div>
      <Header />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex align-items-center justify-content-between flex-md-row flex-column">
                <h3 className="mb-0">Blocked Users List</h3>
                {/* <Form>
                  <InputGroup className="input-group-alternative mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="form-control-alternative"
                      placeholder="Search by name"
                      type="text"
                      value={search}
                      onChange={handleSearch}
                    />
                  </InputGroup>
                </Form> */}
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Blocker Name</th>
                    <th scope="col">Blocker Details</th>
                    <th scope="col" className="text-center">
                      Blocked User's Id & Details
                    </th>
                    {/* <th scope="col">Timestamp</th> */}
                  </tr>
                </thead>
                <tbody>
                  {users.length > 0 ? (
                    users.map((user) => (
                      <tr key={user.user_id}>
                        <td>{user.username}</td>
                        <td>
                          <Link to={`/admin/view-user/${user.user_id}`}>
                            View Details
                          </Link>
                        </td>
                        <td className="text-center">
                          {user.blocked_users.map((user_id, i) => {
                            return (
                              <div key={i}>
                                <Link to={`/admin/view-user/${user_id}`}>
                                  {user_id}
                                </Link>
                              </div>
                            );
                          })}
                        </td>
                        {/* <td>
                          {user.blocked_on.map((item, i) => {
                            return <div key={i}>{item}</div>;
                          })}
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <th colSpan="5">
                        {loading ? "Loading..." : "No Data Found!!"}
                      </th>
                    </tr>
                  )}
                </tbody>
              </Table>
              {search.length < 1 && (
                <CardFooter className="py-4">
                  <TablePagination
                    upper={pagination.upperPageBound}
                    lower={pagination.lowerPageBound}
                    page={pagination.page}
                    btnNextClick={btnNextClick}
                    btnPrevClick={btnPrevClick}
                    count={pagination.count}
                    rowsPerPage={pagination.rowsPerPage}
                    handleChangePage={handleChangePage}
                  />
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default Index;
