import React, { useState } from "react";

// reactstrap components
import { Button, Modal } from "reactstrap";

const ModalDialog = ({
  type,
  toggleModal,
  isOpen,
  data,
  handleAcceptRequest,
  handleRejectRequest,
}) => {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={toggleModal}
    >
      <div className="modal-header">
        <h3 className="modal-title">
          {type == "accept" ? `Accept the request` : `Reject the request`}
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggleModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body pt-2 pb-2 pl-4 pr-4">
        <p className="m-0">{`Are you sure you want to ${type} the ${data.username}'s request?`}</p>
      </div>
      <div className="modal-footer">
        <Button
          color={type === "accept" ? "success" : "danger"}
          // color="primary"
          type="button"
          onClick={type == "accept" ? handleAcceptRequest : handleRejectRequest}
        >
          {type == "accept" ? `Accept` : `Reject`}
        </Button>
        <Button
          color={type === "accept" ? "danger" : "success"}
          data-dismiss="modal"
          type="button"
          onClick={toggleModal}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};
export default ModalDialog;
